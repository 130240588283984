'use client';
import { GroupDataResponse } from "@/restapi/group";
import React, { useContext, createContext, useState, ReactNode } from "react";


type GroupContextProps = {
  prevPathname: string | null;
  setPrevPathname: (value: string | null) => void;
  currentGroup: GroupDataResponse | null;
  setCurrentGroup: (group: GroupDataResponse | null) => void;
};

// コンテキストを作成
const GroupContext = createContext<GroupContextProps>({
  prevPathname: null,
  setPrevPathname: (value: string | null) => { },
  currentGroup: null,
  setCurrentGroup: (group: GroupDataResponse | null) => { },
});

// プロバイダーコンポーネント
export const GroupContextProvider: React.FC<{ children?: ReactNode }> = ({
  children,
}) => {
  const [prevPathname, setPrevPathname] = useState<string | null>(null);
  const [currentGroup, setCurrentGroup] = useState<GroupDataResponse | null>(null);

  return (
    <GroupContext.Provider
      value={{
        prevPathname,
        setPrevPathname,
        currentGroup: currentGroup,
        setCurrentGroup: setCurrentGroup,
      }}
    >
      {children}
    </GroupContext.Provider>
  );
};

// コンテキストを使用するためのフック
export const useGroupContext = (): GroupContextProps =>
  useContext<GroupContextProps>(GroupContext);

export default GroupContextProvider;
