"use client";

import { NOTIFICATION_TYPE_VALUE, NotificationAPI, NotificationGetRequest, NotificationReadGetRequest, NotificationRequest } from "@/restapi/notification";
import APP_CONSTANTS from "@/constants/AppConstants";
import useSWR, { mutate } from "swr";
import { NotificationTypeConst } from "@/domains/models/notification/model/notification";


export const usePostScoringHistory = () => {
  const submit = async (request: NotificationRequest) => {
    try {
      // APIにPOSTリクエストを送信してデータを取得
      const res = await NotificationAPI.postScoringHistory(request);

      // キャッシュを更新
      mutate(`notify-scoring-history-${JSON.stringify(request)}`, res, false);

      return res;
    } catch (err) {
      console.error("Failed to submit", err);
      throw err; // エラー処理
    }
  };

  return {
    submit,
  };
};

export const useNotifyPostNewPostedNumOfNotify = () => {
  const submit = async (request: NotificationRequest) => {
    try {
      // APIにPOSTリクエストを送信してデータを取得
      const res = await NotificationAPI.postNewPostedImage(request);

      // キャッシュを更新
      mutate(`notify-new-posted-${JSON.stringify(request)}`, res, false);

      return res;
    } catch (err) {
      console.error("Failed to submit", err);
      throw err; // エラー処理
    }
  };

  return {
    submit,
  };
};

export const useNotifyGetScoringHistory = (userId: string) => {
  const { data: item, error } = useSWR(
    userId ? `notify-scoring-history-${userId}` : null,
    () => NotificationAPI.getScoringHistory(userId),
    { refreshInterval: APP_CONSTANTS.REFETCH_INTERVAL_TIME }
  );

  return {
    item,
    isLoading: !error && !item,
    isError: error,
  };
};

export const useNotifyGetNewPostedImage = (request: NotificationGetRequest) => {
  const { data: item, error } = useSWR(
    request.userId ? `notify-new-posted-${request.userId}-${request.notificationType}-${request.groupId}` : null,
    () => NotificationAPI.getNewPostedImage(request),
    { refreshInterval: APP_CONSTANTS.REFETCH_INTERVAL_TIME }
  );

  return {
    item,
    isLoading: !error && !item,
    isError: error,
  };
};

export const useNotifyGetReadScoringHistory = (userId: string) => {
  const { data: item, error } = useSWR(
    userId ? `notify-scoring-history-read-${userId}` : null,
    () => NotificationAPI.readScoringHistory(userId),
  );

  return {
    item,
    isLoading: !error && !item,
    isError: error,
  };
};

export const useNotifyGetReadNewPostedImage = (request: NotificationReadGetRequest) => {
  const { data: item, error } = useSWR(
    request.userId ? `notify-new-posted-read-${request.userId}-${request.notificationType}-${request.groupId}` : null,
    () => NotificationAPI.readNewPostedImage(request),
  );

  return {
    item,
    isLoading: !error && !item,
    isError: error,
  };
};
