/**
 * v0 by Vercel.
 * ゴミ箱アイコン
 */
import React from 'react';

export default function TrashIcon (props: any) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 100 100">
      <defs>
        <style>
          {`
            .cls-1 { fill: none; }
            .cls-1, .cls-2 { stroke-width: 0px; }
            .cls-2 { fill: #fff; }
          `}
        </style>
      </defs>
      <g>
        <g>
          <g>
            <path className="cls-2" d="M91.473,11.42h-35.827c-.567,0-1.027-.457-1.027-1.021v-5.609c0-2.399-1.764-4.541-4.166-4.769-2.755-.261-5.073,1.886-5.073,4.571v5.806c0,.564-.46,1.021-1.027,1.021H8.527c-.567,0-1.027.457-1.027,1.021v6.124c0,.564.46,1.021,1.027,1.021h82.947c.567,0,1.027-.457,1.027-1.021v-6.124c0-.564-.46-1.021-1.027-1.021Z" />
            <g>
              <path className="cls-2" d="M34.776,76.26c-2.268,0-4.106-1.828-4.106-4.083v-31.958c0-2.255,1.839-4.083,4.106-4.083s4.106,1.828,4.106,4.083v31.958c0,2.255-1.839,4.083-4.106,4.083Z" />
              <path className="cls-2" d="M50,76.26c-2.268,0-4.106-1.828-4.106-4.083v-31.958c0-2.255,1.839-4.083,4.106-4.083s4.106,1.828,4.106,4.083v31.958c0,2.255-1.839,4.083-4.106,4.083Z" />
              <path className="cls-2" d="M65.224,76.26c-2.268,0-4.106-1.828-4.106-4.083v-31.958c0-2.255,1.839-4.083,4.106-4.083s4.106,1.828,4.106,4.083v31.958c0,2.255-1.839,4.083-4.106,4.083Z" />
            </g>
          </g>
          <path className="cls-2" d="M83.415,100c.546,0,.997-.425,1.025-.967l3.826-72.404c.031-.584-.437-1.074-1.025-1.074h-6.569c-.548,0-1,.428-1.025.973l-3.027,64.334c-.026.545-.477.973-1.025.973H24.41c-.548,0-1-.428-1.025-.973l-3.027-64.334c-.026-.545-.477-.973-1.025-.973h-6.569c-.588,0-1.056.49-1.025,1.074l3.826,72.404c.029.542.479.967,1.025.967h66.826Z" />
        </g>
        <rect className="cls-1" y="0" width="100" height="100" />
      </g>
      <rect className="cls-1" width="100" height="100" />
    </svg>
  );
}
