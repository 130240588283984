'use client';

import { useGroupsNotify } from "@/hooks/group/useGroupsNotify";
import { User } from "next-auth";

/**
 * v0 by Vercel.
 * 友達アイコン
 */
export default function UsersActiveIconLink({ user }: { user: User }) {
	const userId = user.id;
	const { data: notify, isLoading, isError } = useGroupsNotify({
		userId,
	});

	if (isError || isLoading || !notify) {
		return <svg
			xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 100 100">
			<defs>
				<style>
					{`
            .cls-1 { fill: none; }
            .cls-1, .cls-2 { stroke-width: 0px; }
            .cls-2 { fill: #fff; }
          `}
				</style>
			</defs>
			<g>
				<path className="cls-2" d="M74.907,43.04c-.567-.29-.618-1.088-.094-1.451,6.269-4.34,10.26-11.732,9.814-20.026C84.012,10.119,74.724.756,63.273.045c-2.191-.136-4.318.043-6.347.485,9.367,2.161,17.754,10.756,18.265,21.033.412,8.299-5.038,15.695-10.832,20.034-.498.373-.447,1.141.098,1.443,12.035,6.654,23.418,20.056,25.521,56.486.015.264.233.474.497.474h8.992c.289,0,.519-.25.501-.538-2.262-36.382-12.041-49.772-25.06-56.422Z" />
				<path className="cls-2" d="M76.339,99.462c-2.262-36.382-12.041-49.772-25.06-56.422-.567-.29-.618-1.088-.094-1.451,6.269-4.34,10.26-11.732,9.815-20.026C60.384,10.119,51.096.756,39.644.045,26.366-.778,15.339,9.735,15.339,22.821c0,7.778,3.897,14.646,9.846,18.766.525.364.477,1.162-.091,1.453C12.074,49.69,2.295,63.08.033,99.462c-.018.288.212.538.501.538h75.304c.289,0,.519-.25.501-.538Z" />
			</g>
			<rect className="cls-1" y="0" />
		</svg >
	}

	return (
		<svg
			xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 100 100">
			<defs>
				<style>
					{`
            .cls-1 { fill: none; }
            .cls-1, .cls-2 { stroke-width: 0px; }
            .cls-2 { fill: #fff; }
          `}
				</style>
			</defs>
			<g>
				<path className="cls-2" d="M74.907,43.04c-.567-.29-.618-1.088-.094-1.451,6.269-4.34,10.26-11.732,9.814-20.026C84.012,10.119,74.724.756,63.273.045c-2.191-.136-4.318.043-6.347.485,9.367,2.161,17.754,10.756,18.265,21.033.412,8.299-5.038,15.695-10.832,20.034-.498.373-.447,1.141.098,1.443,12.035,6.654,23.418,20.056,25.521,56.486.015.264.233.474.497.474h8.992c.289,0,.519-.25.501-.538-2.262-36.382-12.041-49.772-25.06-56.422Z" />
				<path className="cls-2" d="M76.339,99.462c-2.262-36.382-12.041-49.772-25.06-56.422-.567-.29-.618-1.088-.094-1.451,6.269-4.34,10.26-11.732,9.815-20.026C60.384,10.119,51.096.756,39.644.045,26.366-.778,15.339,9.735,15.339,22.821c0,7.778,3.897,14.646,9.846,18.766.525.364.477,1.162-.091,1.453C12.074,49.69,2.295,63.08.033,99.462c-.018.288.212.538.501.538h75.304c.289,0,.519-.25.501-.538Z" />
			</g>
			<rect className="cls-1" y="0" />
			{notify.exists &&
				<g transform="translate(63, 63)">
					<circle cx="20" cy="20" r="20" fill="red" />
					<text x="20" y="27" fontSize="20" fill="white" textAnchor="middle">
					</text>
				</g>
			}
		</svg >
	);
}
