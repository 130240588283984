"use client";

import React from "react";
import Image from "next/image";
import "./rankingImage.css";
import { useTranslation } from "@/lib/react/i18n";

const RankingHiddenImage: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className="relative group grid [grid-template-areas:stack] overflow-hidden rankScoreList">
      <Image
        width={300}
        height={300}
        className="[grid-area:stack] object-cover w-full aspect-square"
        src={'/dummy-500X500.png'}
        priority
        alt={""} />
    </div>
  );
};

export default RankingHiddenImage;
