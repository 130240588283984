"use client";

import React, { useState, MouseEvent, Dispatch, SetStateAction, FC } from "react";
import { Button } from "@/components/ui/button";
import ScoreInputModal from "@/components/Score/InputModal";
import { useAppContext } from "@/context/AppContextProvider";
import { useTranslation } from "@/lib/react/i18n";
import { ImageResponseData, ImageResponse } from "@/restapi/image";
import { ScoreData } from "@/restapi/score";
import "./scoremodalButton.css";


type Props = {
  setScoreData: Dispatch<SetStateAction<ScoreData>>;
  imageData: ImageResponseData;
  setScoreExists: (value: boolean) => void;
}

const ScoreModalButton: FC<Props> = ({ setScoreData, setScoreExists, imageData }) => {
  const [showModal, setShowModal] = useState(false);
  const { visableMode } = useAppContext();
  const { t } = useTranslation();

  const handleCommentClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setShowModal(true);
  };

  if (imageData.scoreExists) {
    return <></>;
  }
  return (
    <>
      <Button
        className={`scoring-btn ${visableMode.isShow ? "fade-in" : "fade-out"}`}
        style={{ zIndex: 100 }}
        variant="link"
        onClick={handleCommentClick}
      >
        {t("SCORING")}
      </Button>
      {showModal && (
        <ScoreInputModal
          imageResponseData={imageData}
          setScoreData={setScoreData}
          setScoreExists={setScoreExists}
          onClose={() => setShowModal(false)}
        />
      )}
    </>
  );
};

export default ScoreModalButton;
