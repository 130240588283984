import useSWRInfinite from "swr/infinite";
import { RankingResponse, RankingAPI } from "@/restapi/ranking";
import { ImageRankingRequest } from "@/restapi/image";
import APP_CONSTANTS from "@/constants/AppConstants";

const fetcher = async (
  _: string,
  page: number,
  request: ImageRankingRequest
): Promise<RankingResponse> => {
  const data: RankingResponse = await RankingAPI.followingImages(request, page);
  return data;
};

export const useRankingFollowingImages = (request: ImageRankingRequest) => {
  const getKey = (
    index: number,
    previousPageData: RankingResponse | null
  ): string | null => {
    if (previousPageData && !previousPageData.data.length) return null;

    const key = `following-images-${request.myUserId}-${request.userId}?page=${index + 1
      }`;

    return key;
  };

  const fetcherWrapper = (key: string): Promise<RankingResponse> => {
    const page = parseInt(key.split("=")[1]);
    return fetcher(key, page, request);
  };

  const { data, error, size, setSize, isValidating } = useSWRInfinite<RankingResponse>(
    getKey,
    fetcherWrapper,
    { refreshInterval: APP_CONSTANTS.REFETCH_INTERVAL_TIME },
  );

  const isLoading = !data && !error;
  const hasMore = data
    ? data[data.length - 1].currentPage < data[data.length - 1].lastPage
    : false;

  const items = data ? data.flatMap((page) => page.data) : [];

  const loadMore = () => {
    if (!isValidating && hasMore) {
      setSize((prevSize) => prevSize + 1);
    }
  };

  return {
    items,
    isLoading,
    isError: error,
    isValidating,
    loadMore,
    hasMore,
  };
};
