import UserDeactiveIcon from "../icons/UserDeactiveIcon";
import { useSession } from "next-auth/react";
import { useNotifyGetNewPostedImage } from "@/hooks/notification/useNotification";
import { NOTIFICATION_TYPE_VALUE } from "@/restapi/notification";

export const UserDeActiveLink = () => {
	const { data: session } = useSession();
	const user = session?.user;
	const {
		item,
		isLoading,
		isError,
	} = useNotifyGetNewPostedImage({
		userId: user?.id ?? '',
		groupId: null,
		notificationType: NOTIFICATION_TYPE_VALUE.NEW_POSTED_IMAGE,
	});

	if (isLoading || isError) {
		return <UserDeactiveIcon />
	}

	if (!item || 1 > item.count) {
		return <UserDeactiveIcon />
	}

	return <UserDeactiveIcon />;
};

export default UserDeActiveLink;