'use client';

import { ImageAPI, ImageGetRequest, ImageResponse, ImagesResponseData } from "@/restapi/image";
import APP_CONSTANTS from "@/constants/AppConstants";
import useSWRInfinite from "swr/infinite";

const fetcher = async (
  _: string,
  page: number,
  request: ImageGetRequest
): Promise<ImageResponse> => {
  const data = await ImageAPI.index(request, page);
  return data;
};

export const useOrderByImages = (
  request: ImageGetRequest,
  initialSize: number
): ImagesResponseData => {
  const getKey = (
    index: number,
    previousPageData: ImageResponse | null
  ): string | null => {
    const { orderBy = 'desc', orderCol = 'timestamp', isNotScored = false, authUuid = '', isFollwoing = false, groupId = null } = request;
    // 前のページにデータがない場合、終了
    if (previousPageData && !previousPageData.data.length) return null;

    return `all-images?page=${index + 1
      }-${orderBy}-${orderCol}-${isNotScored}-${authUuid}-${isFollwoing}-${groupId}`;
  };

  const fetcherWrapper = (key: string): Promise<ImageResponse> => {
    const page = parseInt(key.split("=")[1]); // キーからページ番号を抽出
    return fetcher(key, page, request);
  };

  const { data, error, size, setSize, isValidating } =
    useSWRInfinite<ImageResponse>(getKey, fetcherWrapper, {
      revalidateOnFocus: false,
      initialSize,
      refreshInterval: APP_CONSTANTS.REFETCH_INTERVAL_TIME,
    });

  const isLoading = !data && !error;
  const hasMore = data
    ? data[data.length - 1].currentPage < data[data.length - 1].lastPage
    : false;

  const items = data ? data.flatMap((page) => page.data) : [];

  const loadMore = () => {
    if (!isValidating && hasMore) {
      setSize(size + 1);
    }
  };

  return {
    items,
    isLoading,
    isValidating,
    isError: error,
    loadMore,
    hasMore,
  };
};
