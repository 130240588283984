import React from "react";

interface UserActiveIconProps {
	color?: string;
	notificationCount?: number;
}

const UserActiveIcon: React.FC<UserActiveIconProps> = ({
	color = "#fff",
	notificationCount = 0,
}) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			version="1.1"
			viewBox="0 0 105 100" // viewBoxを広げたままにします
		>
			<defs>
				<style>
					{`
            .cls-1 { fill: none; }
            .cls-1, .cls-2 { stroke-width: 0px; }
            .cls-2 { fill: ${color}; } // アイコンの色をpropsから取得
          `}
				</style>
			</defs>
			<path className="cls-2" d="M6.065,100h87.869c.3,0,.536-.265.501-.564-2.684-22.956-14.055-41.762-29.179-49.459-.666-.339-.709-1.27-.094-1.695,7.297-5.041,11.941-13.619,11.422-23.243C75.868,11.75,65.045.878,51.7.053c-15.474-.957-28.324,11.252-28.324,26.447,0,9.03,4.539,17.003,11.469,21.787.612.422.56,1.353-.102,1.69-15.123,7.697-26.495,26.503-29.179,49.459-.035.298.201.564.501.564Z" />
			<rect className="cls-1" />
			{notificationCount > 0 && (
				<g transform="translate(63, 63)">
					<circle cx="20" cy="20" r="20" fill="red" />
					<text x="20" y="27" fontSize="20" fill="white" textAnchor="middle">
						{notificationCount > 99 ? 99 : notificationCount}
					</text>
				</g>
			)}
		</svg>
	);
};

export default UserActiveIcon;
