"use client";

import React, { useState, useEffect, useRef, ChangeEvent } from "react";
import ReactDOM from "react-dom";
import { Button } from "@/components/ui/button";
import ImageIcon from "@/components/icons/ImageIcon";
import convertToUploadableImageFile from "@/services/image/image-service";

import { useAppContext } from "@/context/AppContextProvider";
import CloseIcon from "@/components/icons/CloseIcon";
import { usePathname } from "next/navigation";
import { useTranslation } from "@/lib/react/i18n";
import { adjustImageOrientation } from "@/lib/file/image";
import { BrowserTypeChecker } from "@/lib/utils/browser/browser-utils";
import { useUserIconUpdate } from "@/hooks/user/useUserIconUpdate";
import { UserProfile } from "@/restapi/user";
import useEcho from "@/hooks/useEcho";
import "@/components/Pict/imageUploadModal.css";
import ImageIconComponent from "../icons/ImageIconComponent";

export type IconImageSizeProps = {
  mobileSize: {
    width: number;
    height: number;
  };
  pcSize: {
    width: number;
    height: number;
  };
};

type UserIconUploadEventResponse = {
  fileName: string;
  fileUrl: string;
}

const UserIconUploadModal = ({
  userProfile: _userProfile,
  plusDisabledIcon = false,
  sizeProps = {
    mobileSize: {
      width: 50,
      height: 50,
    },
    pcSize: {
      height: 100,
      width: 100,
    },
  },
}: {
  sizeProps?: IconImageSizeProps;
  plusDisabledIcon?: boolean;
  userProfile: UserProfile | undefined;
}) => {
  const { setIsUploading, isUploading, isMobile } = useAppContext();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const { submitUserIconUpdate } = useUserIconUpdate();
  const [imagePreview, setImagePreview] = useState<string | null>(
    _userProfile?.userIconFile?.fileUrl ?? null
  );

  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();
  const [accept, setAccept] = useState("image/*");

  const [userProfile, setUserProfile] = useState<UserProfile | undefined>(undefined)
  useEffect(() => {
    if (_userProfile) {
      setUserProfile(_userProfile);
      setImagePreview(_userProfile.userIconFile?.fileUrl ?? null);
    }
  }, [_userProfile])

  // アイコン更新時のリアルタイム反映
  // const channel = `channel-user-icon-upload-${userProfile?.uuid ?? ''}`;
  // const userIconEcho = useEcho();
  // useEffect(() => {
  //   if(userIconEcho) {
  //     userIconEcho
  //     .channel(channel)
  //     .listen("UserIconUploadEvent", (response: UserIconUploadEventResponse) => {
  //       setImagePreview(response.fileUrl);
  //     });
  //   }
  // }, [userIconEcho]);

  useEffect(() => {
    const browserTypeChecker = new BrowserTypeChecker();
    if (navigator !== undefined && browserTypeChecker.isSafari()) {
      setAccept("image/*");
    } else {
      setAccept("image/*,image/heif, image/heic");
    }
  }, []);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  // ファイルのバリデーション。15MB未満でかつ画像ファイルのみに制限している
  const validateFile = async (selectedFile: File): Promise<boolean> => {
    const limitFileSize = 15 * 1024 * 1024;
    if (selectedFile.size > limitFileSize) {
      return false;
    }
    return true;
  };

  const handleButtonClick = (e: React.MouseEvent) => {
    e.preventDefault();
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  // 画像を選択する
  const handleImageSelect = async (e: ChangeEvent<HTMLInputElement>) => {
    setErrorMsg(null);
    setLoading(true);
    e.preventDefault();
    const selectedFile = e.target.files?.[0];

    if (selectedFile && (await validateFile(selectedFile))) {
      let convertedFile = selectedFile;
      try {
        // アップロード可能な形式に変換
        convertedFile = await convertToUploadableImageFile(convertedFile);
        // サムネイル表示可能な形式に変換
        const canvas = await adjustImageOrientation(convertedFile);

        setImagePreview(canvas.toDataURL("image/webp"));
        setFile(convertedFile);
        setErrorMsg(null);
        setLoading(false);
      } catch (error) {
        console.error(error);
        if (error instanceof Error) {
          setErrorMsg(error.message);
        }
        setFile(convertedFile);
        setImagePreview(null);
        setLoading(false);
        return;
      }
    } else {
      setLoading(false);
    }
  };

  const handleUpload = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsUploading(true);
    try {
      if (!file) {
        setErrorMsg("File not selected.");
        setIsUploading(false); // エラー時にアップロード中ステータスを解除
        closeModal();
        return;
      }

      if (userProfile === undefined) {
        setErrorMsg("user not found.");
        setIsUploading(false); // エラー時にアップロード中ステータスを解除
        closeModal();
        return;
      }

      closeModal();
      const formData = new FormData();
      formData.append("file", file);
      formData.append("userId", String(userProfile.uuid));
      formData.append("beforeFileName", String(userProfile.userIconFile?.fileName ?? ''));

      const fetchUploadImage = await fetch("/api/upload/user-icon", {
        method: "POST",
        body: formData,
      });

      const uploadImageRes = await fetchUploadImage.json();
      if (fetchUploadImage.status == 200) {
        const fileName = uploadImageRes.fileName;
        const downloadURL = uploadImageRes.downloadURL;

        // laravel側APIに登録・更新
        const response = await submitUserIconUpdate({
          uuid: userProfile.uuid,
          fileName,
          fileUrl: downloadURL,
        });

        // リアルタイム反映できない場合の一次対応処理
        if (response) {
          if (typeof window !== "undefined") {
            window.location.reload();
          }
        }

      } else {
        alert(uploadImageRes.message);
      }
    } catch (e: any) {
      if (e instanceof Error) {
        console.error("Error uploading image:", e.message);
        setErrorMsg(`Error: ${e.message}`);
      } else {
        console.error("Error uploading image:", e);
        setErrorMsg(`Error: ${e}`);
      }
    }
    setFile(null);
    setImagePreview(null);
    setIsUploading(false);
  };


  return (
    <>
      {isUploading && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-100"></div>
      )}

      <button
        className="bg-transparent hover:bg-transparent"
        onClick={openModal}
        disabled={plusDisabledIcon}
      >
        <ImageIconComponent
          fileURL={imagePreview ?? ""}
          size={isMobile ? sizeProps.mobileSize : sizeProps.pcSize}
          plusDisabledIcon={isUploading ? true : plusDisabledIcon}
        />
      </button>

      {isModalOpen &&
        ReactDOM.createPortal(
          <div
            className="fixed z-50 flex items-center justify-center modal-overlay"
            onClick={(e) => e.stopPropagation()}
          >
            <div
              className="fixed bg-black bg-opacity-50"
              onClick={closeModal}
              style={{ pointerEvents: "auto" }}
            ></div>

            <div
              className={`bg-black rounded-lg overflow-hidden transform transition-all duration-300 ${isModalOpen
                ? "translate-y-0 md:translate-y-0"
                : "-translate-y-full md:translate-y-full"
                } w-96 max-w-none h-auto md:h-auto max-h-[90vh]`}
            >
              <button
                className="absolute top-2 right-2 text-gray-500"
                onClick={closeModal}
                style={{ pointerEvents: "auto" }}
              >
                <CloseIcon className="w-6 h-6" />
              </button>

              <form
                onSubmit={handleUpload}
                className="form"
                style={{ pointerEvents: "auto" }}
              >
                <div className="flex flex-col items-center justify-center space-y-4 p-4">
                  <label className="text-white pb-2">
                    {t("Registering a user icon")}
                  </label>
                  {errorMsg && <span className="text-red-600">{errorMsg}</span>}
                  <input
                    className="flex"
                    type="file"
                    accept={accept}
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={handleImageSelect}
                  />
                  <div className="flex items-center space-x-2 bg-gray-800">
                    <Button
                      className="w-[350px] flex items-center space-x-2 bg-gray-800 hover:bg-black bg-transparent hover:bg-gradient-to-r hover:from-[#0000FF] hover:to-[#E140FF]"
                      variant="outline"
                      onClick={handleButtonClick}
                    >
                      <ImageIcon className="w-6 h-6" />
                      <span className="text-white">{t("Select an icon")}</span>
                    </Button>
                  </div>
                  {imagePreview && (
                    <ImageIconComponent
                      fileURL={imagePreview}
                      plusDisabledIcon={true}
                    />
                  )}
                  {loading && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-100">
                      <span className="text-white text-xl font-bold">
                        Loading...
                      </span>
                    </div>
                  )}
                </div>
                <div className="p-4">
                  <button className="border-gray-300 bg-gray-800 text-white py-2 px-4 mt-2 rounded bg-transparent hover:bg-gradient-to-r hover:from-[#0000FF] hover:to-[#E140FF] w-full">
                    {t("Register")}
                  </button>
                </div>
              </form>
            </div>
          </div>,
          document.body
        )}
    </>
  );
};

export default UserIconUploadModal;
