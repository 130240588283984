"use client";

import { useAppContext } from "@/context/AppContextProvider";
import { useSession } from "next-auth/react";
import Link from "next/link";
import { FC } from "react";
import SignInButton from "@/components/SignInButton";
import Image from "next/image";
import { useUserGetProfile } from "@/hooks/user/useUserGetProfile";
import UserIconUploadModal, { IconImageSizeProps } from "@/components/Profile/UserIconUploadModal";

const sizeProps: IconImageSizeProps = {
  mobileSize: {
    width: 50,
    height: 50,
  },
  pcSize: {
    width: 50,
    height: 50,
  }
}

const HomeHeader: FC = () => {
  const { visableMode } = useAppContext();
  const { data: session } = useSession();
  const user = session?.user;
  const { data: userProfile, isLoading, isError } = useUserGetProfile({
    userUuid: user?.id ?? ''
  });

  if (isLoading || isError) {
    return <></>;
  }

  return (
    <div
      className={`fixed top-0 left-1/2 transform -translate-x-1/2 w-full min-w-screen-sm flex flex-row-reverse justify-between bg-opacity-50 py-2 settingArea ${visableMode.isShow ? "fade-in" : "fade-out"}`}
      style={{ zIndex: "100" }}
    >
      <div
        className={`logoArea `}
      >
        <Link className="text-white" href="/">
          <div className="logoBox">
            <Image
              alt="picsle logo"
              src="/picsle_logo.svg"
              width={300}
              height={300}
              style={{ width: "100%", height: "auto" }}
              fill={false}
              priority={false}
            />
          </div>
        </Link>
      </div>
      <div
        className="mr-2"
      >{user && userProfile ? (
        <UserIconUploadModal
          sizeProps={sizeProps}
          userProfile={userProfile}
          plusDisabledIcon={true} />) : <SignInButton />}</div>
    </div>
  );
};

export default HomeHeader;
