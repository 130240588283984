"use client";

import useSWR from "swr";
import { GroupApi, GroupsNotifyRequest } from "@/restapi/group";
import APP_CONSTANTS from "@/constants/AppConstants";

export const useGroupsNotify = (request: GroupsNotifyRequest) => {
  const { data, error } = useSWR(
    `groups-notify-${request.userId}`,
    () => GroupApi.notify(request),
    { refreshInterval: APP_CONSTANTS.REFETCH_INTERVAL_TIME },
  );

  return {
    data,
    isLoading: !error && !data,
    isError: error,
  };
};
