"use client";

import React from "react";
import Image from "next/image";
import { ImageResponseData } from "@/restapi/image";
import "./rankingImage.css";

const RankingImage: React.FC<{
  image: ImageResponseData;
  hrefEnable?: boolean;
  onClick: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    imageId: string
  ) => void;
}> = ({ image, onClick }) => {

  // 平均点リアルタイム反映
  // const echo = useEcho();
  // useEffect(() => {
  //   if (echo) {
  //     echo
  //       .channel(`channel-score-post-${image.uuid}`)
  //       .listen("ScorePostEvent", (response: ScorePostEventResponse) => {
  //         const { averageScore, totalScore, posts, userId: _userId } = response;
  //         setScoreData({
  //           averageScore,
  //           totalScore,
  //           posts,
  //         });
  //       });
  //   }
  // }, [echo]);

  return (
    <div className="relative group grid [grid-template-areas:stack] overflow-hidden rankScoreList">
      <div className="cursor-pointer" onClick={(e) => onClick(e, image.uuid)}>
        <Image
          width={300}
          height={300}
          alt={image.imageFile.fileName}
          className="[grid-area:stack] object-cover w-full aspect-square"
          src={image.imageFile.fileUrl}
          priority
        />
        {typeof image.averageScore === "number" &&
          !isNaN(image.averageScore) && (
            <div className="text-with-outline transform rankScoreTxtBox">
              <span className="rankScoreTxt">
                {image.averageScore}
              </span>
            </div>
          )}
      </div>
    </div>
  );
};

export default RankingImage;
