'use client';

import useSWRInfinite from "swr/infinite";
import { RankingResponse, RankingAPI } from "@/restapi/ranking";
import { ImageRankingRequest, ImagesResponseData } from "@/restapi/image";
import APP_CONSTANTS from "@/constants/AppConstants";

const fetcher = async (
  _: string,
  page: number,
  request: ImageRankingRequest
): Promise<RankingResponse> => {
  const data: RankingResponse = await RankingAPI.latestImages(request, page);
  return data;
};

export const useLatestImages = (request: ImageRankingRequest): ImagesResponseData => {
  const getKey = (index: number, previousPageData: RankingResponse | null): string | null => {
    if (previousPageData && !previousPageData.data.length) return null;
    const groupId = request.groupId ?? '0';

    return request.userId
      ? `user-latest-scored-images-${groupId}-${request.myUserId}-${request.userId}-?page=${index + 1}`
      : `latest-scored-images-${groupId}-?page=${index + 1}`;
  };

  const fetcherWrapper = (key: string): Promise<RankingResponse> => {
    const page = parseInt(key.split("=")[1]);
    return fetcher(key, page, request);
  };

  const { data, error, size, setSize, isValidating, isLoading } = useSWRInfinite<RankingResponse>(
    getKey,
    fetcherWrapper,
    { refreshInterval: APP_CONSTANTS.REFETCH_INTERVAL_TIME },
  );

  const hasMore = data ? data[data.length - 1].currentPage < data[data.length - 1].lastPage : false;
  const items = data ? data.flatMap((page) => page.data) : [];

  const loadMore = () => {
    if (!isValidating && hasMore) {
      setSize(size + 1);
    }
  };

  return {
    items,
    isLoading,
    isError: error,
    loadMore,
    hasMore,
    isValidating,
  };
};