'use client';
import React, { useContext, createContext, useState, ReactNode } from "react";
import { User } from "next-auth";
import { ImageResponseData } from "@/restapi/image";


type ImageSettingContextProps = {
  currentImageId: number | null;
  setCurrentImageId: (imageId: number | null) => void;
};

// コンテキストを作成
const ImageSettingContext = createContext<ImageSettingContextProps>({
  currentImageId: null,
  setCurrentImageId: (id: number | null) => { },
});

// プロバイダーコンポーネント
export const ImageSettingContextProvider: React.FC<{ children?: ReactNode }> = ({
  children,
}) => {
  const [currentImageId, setCurrentImageId] = useState<number | null>(null);

  return (
    <ImageSettingContext.Provider
      value={{
        currentImageId,
        setCurrentImageId,
      }}
    >
      {children}
    </ImageSettingContext.Provider>
  );
};

// コンテキストを使用するためのフック
export const useImageSettingContext = (): ImageSettingContextProps =>
  useContext<ImageSettingContextProps>(ImageSettingContext);

export default ImageSettingContextProvider;
