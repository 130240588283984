"use client";

import { mutate } from "swr";
import { ImageAPI, ImageDeleteRequest } from "@/restapi/image";

export const useDeleteImage = () => {
  const submitDelete = async (request: ImageDeleteRequest) => {
    try {
      // APIにPOSTリクエストを送信してデータを取得
      const res = await ImageAPI.delete(request);

      // キャッシュを更新
      mutate(`image-delete-${JSON.stringify(request)}`, res, false);

      return res;
    } catch (err) {
      console.error("Failed to submit score:", err);
      throw err; // エラー処理
    }
  };

  return {
    submitDelete,
  };
};
