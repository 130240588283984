"use client";

import Footer from "@/components/Footer";
import Home from "@/components/Home";
import HomeHeader from "@/components/organisms/header/HomeHeader";
import { useAppContext } from "@/context/AppContextProvider";

export default function Component() {
  const { imageDisplayMode } = useAppContext();
  return (
    <div>
      {imageDisplayMode !== 'FullScreenMode' && (
        <HomeHeader />
      )}
      <div className="pc-container bg-black min-h-screen">
        <div className="mb-20">
          <Home />
        </div>
      </div>
      <Footer />
    </div>
  );
}
