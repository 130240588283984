import axios from "axios";

axios.defaults.baseURL = process.env.NEXT_PUBLIC_REST_API_URL;
axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
axios.defaults.headers.common = {
  Accept: "*/*",
  "Content-Type": "application/json",
};
axios.defaults.withCredentials = true;


// Set the Bearer auth token.
const setBearerToken = (token: string) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

export { axios, setBearerToken };
