import { User } from "next-auth";
import { URLConstants } from "@/constants/URLConstants";
import { useAppContext } from "@/context/AppContextProvider";
import { MouseEvent, useState } from "react";
import { usePathname, useRouter } from "next/navigation";
import UsersActiveIconLink from "@/components/icons/UsersActiveIconLink";
import UserActiveIcon from "@/components/icons/UserActiveIcon";
import UsersDeactiveIconLink from "@/components/icons/UsersDeactiveIconLink";
import UserDeactiveIcon from "@/components/icons/UserDeactiveIcon";


const isGroupPath = (path: string): boolean => {
  const groupPathRegex = /^\/group(\/.*)?$/; // "/group" または "/group/" 以降のパスも許容
  return groupPathRegex.test(path);
};


export const GroupFooterLink = ({ isModalOpen, user }: {
  isModalOpen: boolean;
  user: User | undefined
}) => {
  const { isMobile, imageDisplayMode } = useAppContext();
  const [isGroupHovered, setIsGroupHovered] = useState(false);
  const pathname = usePathname();

  const handleClick = (e: MouseEvent<HTMLAnchorElement, globalThis.MouseEvent>) => {
    e.stopPropagation();
    e.preventDefault();

    if (typeof window !== undefined) {
      if (!user) {
        window.location.href = "/signin";

        return;
      }
      if (imageDisplayMode === 'FullScreenMode') {
        window.location.href = pathname;

        return;
      }

      window.location.href = URLConstants.group.pathname;
    }
  }

  return (
    <div>
      <a
        className={`${isModalOpen ? "linkDisabled" : "cursor-pointer"}`}
        onClick={handleClick}
        onMouseEnter={() => setIsGroupHovered(true)}
        onMouseLeave={() => setIsGroupHovered(false)}
      >
        {isGroupPath(pathname) ||
          (isGroupHovered && !isMobile) ? (
          user ? <UsersActiveIconLink user={user} /> : <UserActiveIcon />)
          : (user ? <UsersDeactiveIconLink user={user} /> : <UserDeactiveIcon />)
        }
      </a>
    </div>
  )
}