"use client";

import useSWR from "swr";
import { GroupApi, GroupExistsJoinMemberRequest } from "@/restapi/group";
import APP_CONSTANTS from "@/constants/AppConstants";

export const useGroupExistsJoinMember = (request: GroupExistsJoinMemberRequest) => {
  const { data, error, isLoading } = useSWR(
    `exists-join-member-${request.userId}-${request.groupId}`,
    () => GroupApi.existsJoinMember(request),
    { refreshInterval: APP_CONSTANTS.REFETCH_INTERVAL_TIME }
  );

  return {
    data,
    isLoading,
    isError: error,
  };
};
