"use client";

import React, { useEffect, useState } from "react";
import Link from "next/link";
import UserIconUploadModal from "@/components/Profile/UserIconUploadModal";
import { useAppContext } from "@/context/AppContextProvider";
import { useTranslation } from "@/lib/react/i18n";
import { ImageDeleteEventResponse, ImageResponseData } from "@/restapi/image";
import useEcho from "@/hooks/useEcho";
import { ScoreData } from "@/restapi/score";
import { useRouter } from "next/navigation";
import { useUserGetProfile } from "@/hooks/user/useUserGetProfile";
import { ScorePostEventResponse, UserProfile } from "@/restapi/user";
import "@/components/image/full-screen/fullScreenImage.css";
import "./ImageAverageModal.css";
import Image from "next/image";
import { useImageById } from "@/hooks/image/useImageById";
import { URLConstants } from "@/constants/URLConstants";

export const ImageAverageModal: React.FC<{
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
  image: ImageResponseData;
}> = ({ setIsModalOpen, image: _image }) => {
  const [userProfile, setUserProfile] = useState<UserProfile | undefined>(undefined)
  const { isMobile } = useAppContext();
  const { visableMode } = useAppContext();
  const { t, loading: tLoading } = useTranslation();
  const router = useRouter();
  const [scoreData, setScoreData] = useState<ScoreData>({
    posts: _image.posts ?? 0,
    averageScore: _image.averageScore,
    totalScore: _image.totalScore ?? 0,
  });
  const [image, setImage] = useState<ImageResponseData | undefined>(undefined);
  const { item, isLoading } = useImageById({
    imageUuid: _image.uuid,
    authUuid: _image.uuid,
  })


  const {
    data,
    isError: userProfileError,
    isLoading: userProfileLoading,
  } = useUserGetProfile({
    userUuid: _image.userUuid,
  });

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (data) {
      setUserProfile(data);
    }
  }, [data]);


  useEffect(() => {
    if (item) {
      setImage(item);
    }
    else {
      if (!isLoading && typeof window !== undefined) {
        window.location.href = URLConstants.home.pathname;
        return;
      }
    }
  }, [item]);

  // 平均点リアルタイム反映
  // const echo = useEcho();
  // useEffect(() => {
  //   if (echo) {
  //     echo
  //       .channel(`channel-score-post-${image.uuid}`)
  //       .listen("ScorePostEvent", (response: ScorePostEventResponse) => {
  //         const { averageScore, totalScore, posts, userId: _userId } = response;
  //         setScoreData({
  //           averageScore,
  //           totalScore,
  //           posts,
  //         });
  //       });
  //   }
  // }, [echo]);

  // 画像削除時のリアルタイム反映処理
  // const echoDelete = useEcho();
  // useEffect(() => {
  //   if (echoDelete) {
  //     echoDelete
  //       .channel(`channel-image-delete-${image.userUuid}`)
  //       .listen("ImageDeleteEvent", (response: ImageDeleteEventResponse) => {
  //         // 画像が削除された場合はトップ画面にリダイレクトする
  //         if (response.imageId === image.uuid) {
  //           router.push("/");
  //         }
  //       });
  //   }
  // }, [echoDelete]);


  if (userProfileLoading || !image) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-black text-white p-4">
        <div className="mb-10 text-center">Loading...</div>
      </div>
    );
  }

  if (userProfileError) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-black text-red-600 p-4">
        <div className="mb-10 text-center"></div>
      </div>
    );
  }

  return (
    <div className="pc-container bg-black min-h-screen">
      <div className="bg-black text-white min-h-screen flex flex-col items-center">
        <header className="w-full flex justify-between items-center p-4">
          <div
            className={`logoArea ${visableMode.isShow ? "fade-in" : "fade-out"
              }`}
          >
            <Link className="text-white" href="/">
              <div className="logoBox">
                <Image
                  alt="picsle logo"
                  src="/picsle_logo.svg"
                  width={300}
                  height={300}
                  style={{ width: "100%", height: "auto" }}
                  sizes="(max-width: 768px) 100vw, 300px"
                  fill={false}
                  priority
                />
              </div>
            </Link>
          </div>
        </header>
        <div className="text-center mt-0 mb-4">
          <UserIconUploadModal
            userProfile={userProfile}
            plusDisabledIcon={true}
            sizeProps={{
              mobileSize: {
                width: 60,
                height: 60,
              },
              pcSize: {
                height: 60,
                width: 60,
              },
            }}
          />
        </div>
        <div className="flex-grow flex flex-col items-center">
          <div className="imageArea">
            <img src={image.imageFile.fileUrl}
              alt={image.imageFile.fileName}
            />
            <div className="mt-2">
              <div className="flex justify-between">
                <div>
                  {image.averageScore && (
                    <>
                      <div
                        className={`text-center font-bold ${isMobile ? "text-2xl" : "text-3xl"
                          }`}
                      >
                        {scoreData.averageScore}
                      </div>
                      <div>{t("average")}</div>
                    </>
                  )}
                </div>
                <div>
                  <div
                    className={`text-center font-bold ${isMobile ? "text-lg" : "text-xl"
                      }`}
                  >
                    {scoreData.posts}
                  </div>
                  <div>{t("ppl")}</div>
                </div>
              </div>
            </div>
            <div className="mt-1 text-center">
              <div className="text-2xl font-bold">{scoreData.totalScore}</div>
              <div>
                {t("total")} {t("score")}
              </div>
            </div>
          </div>
        </div>
        <div
          className="absolute top-2 right-4 text-white text-2xl cursor-pointer"
          onClick={closeModal}
        >
          &times;
        </div>
      </div>
    </div>
  );
};
