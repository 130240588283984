import GroupImageDeleteModal from "@/components/group/modals/GroupImageDeleteModal";
import TrashIcon from "@/components/icons/TrashIcon"
import { useDeleteImage } from "@/hooks/image/useDeleteImage";
import { useImageExcludeFromGroup } from "@/hooks/image/useImageExcludeFromGroup";
import { useTranslation } from "@/lib/react/i18n";
import { GroupDataResponse } from "@/restapi/group";
import { ImageResponseData } from "@/restapi/image";
import { deleteObject, getStorage, ref } from "firebase/storage";
import { usePathname } from "next/navigation";
import { useState } from "react";

export const GroupImageDeleteButton = ({ image, group }: { image: ImageResponseData, group?: GroupDataResponse }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  // グループマイページから画像一覧が表示されてる場合はこの処理を使う
  const { submit: sumbitExcludeFromGroup } = useImageExcludeFromGroup();
  // 画像削除の場合はこっち
  const { submitDelete: submitDeleteImage } = useDeleteImage();
  const pathname = usePathname();
  // urlパスがグループマイページの場合は投稿画像を削除処理が違うので判定する
  const isGroupMypage = /^\/group\/mypage\/[^/]+$/.test(pathname);

  const onOpen = () => {
    setIsOpen(true);
  }

  const onClose = () => {
    setIsOpen(false);
  }
  const onConfirmExcludeFromGroup = async () => {
    try {
      if (group) {
        await sumbitExcludeFromGroup({
          imageId: image.id,
          groupId: group.id,
        });

        const res = await submitDeleteImage({
          uuid: image.uuid,
        });

        // firestoreのstorageを削除
        const storage = getStorage();
        const imageRef = ref(storage, `images/${res.data.fileName}`);
        await deleteObject(imageRef);

      }
      else {
        const res = await submitDeleteImage({
          uuid: image.uuid,
        });

        // 削除失敗した場合
        if (!res.ok) {
          throw new Error(t('Failed to delete photo.'));
        }

        // firestoreのstorageを削除
        const storage = getStorage();
        const imageRef = ref(storage, `images/${res.data.fileName}`);
        await deleteObject(imageRef);
      }

      if (typeof window !== undefined) {
        window.location.href = pathname;
      }
    } catch (error) {
      console.error(error);
      if (typeof window !== undefined) {
        window.location.href = pathname;
      }
    }
  }


  return (
    <>
      {isOpen && <GroupImageDeleteModal onClose={onClose} isOpen={isOpen} onConfirm={onConfirmExcludeFromGroup}
        alertMessages={
          [
            t('Can I delete it?'),
            t("*Photos on the user's My Page will also be deleted."),
          ]
        } />}
      <button
        onClick={(e) => { e.stopPropagation(); onOpen(); }}
        className="cursor-pointer"
      >
        <TrashIcon className="w-10 h-10" />
      </button>
    </>
  )
}