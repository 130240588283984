'use client';

import React, { FC, useEffect, useState } from "react";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  ImageResponseData,
  ImagesResponseData,
} from "@/restapi/image";
import { RANKING_TYPE, RankingTypeConst } from "@/restapi/ranking";
import { useTranslation } from "@/lib/react/i18n";
import { useSession } from "next-auth/react";
import RankingImage from "./RankingImage";
import { GroupDataResponse, GroupTypeValue } from "@/restapi/group";
import { User } from "next-auth";
import RankingHiddenImage from "./RankingHiddenImage";
import { RankingTypeValue, ShowRankingToggles } from "@/components/Ranking";

/**
 *  グループ画像の非表示判定
 * @param group 
 * @param user 
 * @returns 
 */
const isHiddenImageInfo = (group: GroupDataResponse | undefined, user: User | undefined) => {
  if (group) {
    const _user = group.users.find(u => u.userUuid === (user?.id ?? ''))
    if (!_user && group.groupType === GroupTypeValue.PRIVATE) {
      return true;
    }
  }

  return false;
}

type Props = {
  children?: React.ReactNode;
  defaultValue: RankingTypeValue;
  clickkHighest?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
  rankingType: RankingTypeConst;
  clickLowest?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
  clickNotScored?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
  clickLatest?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
  clickFollowing?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
  highestImagesResponseData?: ImagesResponseData;
  lowestImagesResponseData?: ImagesResponseData;
  notScoredImagesResponseData?: ImagesResponseData;
  latestImagesResponseData?: ImagesResponseData;
  clickSelectImage: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    imageId: string
  ) => void;
  followingItems?: ImageResponseData[];
  followingHasMore?: boolean;
  followingGetMore?: () => void | undefined;
  group?: GroupDataResponse;
  className?: string;
  showRankingToggles: ShowRankingToggles;
};

const RankingList: FC<Props> = ({
  defaultValue,
  rankingType,
  clickSelectImage,
  clickkHighest = undefined,
  clickLowest = undefined,
  clickNotScored = undefined,
  clickLatest = undefined,
  clickFollowing = undefined,
  followingItems: _followingItems = undefined,
  followingGetMore = undefined,
  followingHasMore = undefined,
  highestImagesResponseData = undefined,
  lowestImagesResponseData = undefined,
  notScoredImagesResponseData = undefined,
  latestImagesResponseData = undefined,
  group = undefined,
  className = undefined,
  showRankingToggles,
}) => {
  const { data: session } = useSession();
  const user = session?.user;
  const imageHidden = isHiddenImageInfo(group, user);

  const { t, loading } = useTranslation();
  const [highestItems, setHighestItems] = useState<ImageResponseData[]>(
    highestImagesResponseData?.items ?? []
  );
  const [lowestItems, setLowestItems] = useState<ImageResponseData[]>(
    lowestImagesResponseData?.items ?? []
  );
  const [notScoredItems, setNotScoredItems] = useState<ImageResponseData[]>(
    notScoredImagesResponseData?.items ?? []
  );
  const [followingItems, setFollowingItems] = useState<ImageResponseData[]>(
    _followingItems ?? []
  );
  const [latestItems, setLatestItems] = useState<ImageResponseData[]>(
    latestImagesResponseData?.items ?? []
  );
  useEffect(() => {
    if (highestImagesResponseData) {
      setHighestItems(highestImagesResponseData.items ?? []);
    }
  }, [highestImagesResponseData]);

  useEffect(() => {
    if (lowestImagesResponseData) {
      setLowestItems(lowestImagesResponseData.items ?? []);
    }
  }, [lowestImagesResponseData]);

  useEffect(() => {
    if (notScoredImagesResponseData) {
      setNotScoredItems(notScoredImagesResponseData.items ?? []);
    }
  }, [notScoredImagesResponseData]);

  useEffect(() => {
    if (latestImagesResponseData) {
      setLatestItems(latestImagesResponseData.items ?? []);
    }
  }, [latestImagesResponseData]);

  useEffect(() => {
    if (_followingItems) {
      setFollowingItems(_followingItems);
    }
  }, [_followingItems]);

  // 画像投稿時のリアルタイム反映処理
  // const echoPost = useEcho();
  // useEffect(() => {
  //   if (echoPost) {
  //     echoPost
  //       .channel(`channel-image-new-post`)
  //       .listen("ImageNewPostEvent", (response: ImagePostEventResponse) => {
  //         // 画像投稿後にスコアされてない画像と新着投稿に追加
  //         const { image } = response;
  //         setLatestItems([image, ...latestItems]);
  //         setNotScoredItems([image, ...notScoredItems]);
  //         setFollowingItems([image, ...followingItems]);
  //       });
  //   }
  // }, [echoPost]);

  // 画像削除時のリアルタイム反映処理
  // const echoDelete = useEcho();
  // useEffect(() => {
  //   if (echoDelete) {
  //     echoDelete
  //       .channel(`channel-image-delete`)
  //       .listen("ImageNewDeleteEvent", (response: ImageDeleteEventResponse) => {
  //         // 画像削除完了後に各画像一覧リストを更新
  //         const { imageId } = response;
  //         setLatestItems(latestItems.filter((item) => item.uuid !== imageId));
  //         setNotScoredItems(
  //           notScoredItems.filter((item) => item.uuid !== imageId)
  //         );
  //         setHighestItems(highestItems.filter((item) => item.uuid !== imageId));
  //         setLowestItems(lowestItems.filter((item) => item.uuid !== imageId));
  //         setFollowingItems(
  //           followingItems.filter((item) => item.uuid !== imageId)
  //         );
  //       });
  //   }
  // }, [echoDelete]);

  if (loading) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-black text-white p-4">
        <div className="mb-10 text-center">Loading...</div>
      </div>
    );
  }

  return (
    <div className={`mx-auto pc-container ${className}`}>
      <div className="bg-inherit min-h-screen">
        <Tabs defaultValue={defaultValue}>
          <TabsList className="rankListTxt">
            {showRankingToggles.latest && clickLatest && (
              <TabsTrigger
                onClick={clickLatest}
                value="Latest"
                className={`notScoredTxt ${rankingType === RANKING_TYPE.LATEST ? "active  border-b-2 border-gray-300 rounded-none" : "text-white"} data-[state=active]:text-white hover:border-b-2 hover:border-gray-300 hover:text-white `}
                style={{ color: "white" }}
              >
                {t("Latest")}
              </TabsTrigger>
            )}
            {showRankingToggles.highest && clickkHighest && (
              <TabsTrigger
                value="Highest"
                onClick={clickkHighest}
                className={`highestTxt ${rankingType === RANKING_TYPE.HIGHEST ? "active border-b-2 border-gray-300 rounded-none" : "text-white"
                  } data-[state=active]:text-white hover:text-white  hover:border-b-2 rounded-none hover:border-gray-300`}
                style={{ color: "white" }}
              >
                {t("Highest")}
              </TabsTrigger>
            )}
            {showRankingToggles.lowest && clickLowest && (
              <TabsTrigger
                onClick={clickLowest}
                value="Lowest"
                className={`lowestTxt ${rankingType === RANKING_TYPE.LOWHEST ? "active  border-b-2 border-gray-300 rounded-none" : "text-white"
                  } data-[state=active]:text-white hover:text-white  hover:border-b-2 hover:border-gray-300`}
                style={{ color: "white" }}
              >
                {t("Lowest")}
              </TabsTrigger>
            )}
            {showRankingToggles.notScored && clickNotScored && (
              <TabsTrigger
                onClick={clickNotScored}
                value="NotScored"
                className={`notScoredTxt ${rankingType === RANKING_TYPE.NOT_SCORED ? "active  border-b-2 border-gray-300 rounded-none" : "text-white"} data-[state=active]:text-white hover:border-b-2 hover:border-gray-300 hover:text-white `}
                style={{ color: "white" }}
              >
                {t("Not Scored")}
              </TabsTrigger>
            )}

            {showRankingToggles.following && clickFollowing && user && (
              <TabsTrigger
                value="Following"
                onClick={clickFollowing}
                className={`notScoredTxt ${rankingType === RANKING_TYPE.FOLLOWING ? "active  border-b-2 border-gray-300 rounded-none" : ""
                  } data-[state=active]:text-white hover:text-white hover:border-b-2 hover:border-gray-300`}
                style={{ color: "white" }}
              >
                {t("Following")}
              </TabsTrigger>
            )}
          </TabsList>
          {highestItems && highestImagesResponseData && (
            <TabsContent value="Highest">
              <InfiniteScroll
                key={`infinite-scroll-highest-${new Date().getTime()}`}
                dataLength={highestItems.length}
                next={highestImagesResponseData.loadMore}
                hasMore={highestImagesResponseData.hasMore}
                loader={<div>...loading</div>}
              >
                <div className="grid grid-cols-3 w-full max-w-5xl mx-auto px-4">
                  {highestItems.map((item, index) => (
                    imageHidden ?
                      <RankingHiddenImage key={index} />
                      : <RankingImage
                        onClick={clickSelectImage}
                        key={`${item.id}-${index}-high`}
                        image={item}
                      />
                  ))}
                </div>
              </InfiniteScroll>
            </TabsContent>
          )}
          {lowestImagesResponseData && lowestImagesResponseData && (
            <TabsContent value="Lowest">
              <InfiniteScroll
                key={`infinite-scroll-lowest-${new Date().getTime()}`}
                dataLength={lowestItems.length}
                next={lowestImagesResponseData.loadMore}
                hasMore={lowestImagesResponseData.hasMore}
                loader={<div>...loading</div>}
              >
                <div className="grid grid-cols-3 w-full max-w-5xl mx-auto px-4">
                  {lowestItems.map((item, index) => (
                    imageHidden ?
                      <RankingHiddenImage key={index} />
                      : <RankingImage
                        onClick={clickSelectImage}
                        key={`${item.id}-${index}-low`}
                        image={item}
                      />
                  ))}
                </div>
              </InfiniteScroll>
            </TabsContent>
          )}
          {notScoredItems && notScoredImagesResponseData && (
            <TabsContent value="NotScored">
              <InfiniteScroll
                key={`infinite-scroll-notScored-${new Date().getTime()}`}
                dataLength={notScoredItems.length}
                next={notScoredImagesResponseData.loadMore}
                hasMore={notScoredImagesResponseData.hasMore}
                loader={<></>}
              >
                <div className="grid grid-cols-3 w-full max-w-5xl mx-auto px-4">
                  {notScoredItems.map((item, index) => (
                    imageHidden ?
                      <RankingHiddenImage key={index} />
                      : <RankingImage
                        onClick={clickSelectImage}
                        key={`${item.id}-${index}-not-scored`}
                        image={item}
                      />
                  ))}
                </div>
              </InfiniteScroll>
            </TabsContent>
          )}
          {_followingItems && followingGetMore && user && (
            <TabsContent value="Following">
              <InfiniteScroll
                key={`infinite-scroll-following-${new Date().getTime()}`}
                dataLength={followingItems.length}
                next={followingGetMore}
                hasMore={followingHasMore ? followingHasMore : false}
                loader={<div>...loading</div>}
              >
                <div className="grid grid-cols-3 w-full max-w-5xl mx-auto px-4">
                  {followingItems.map((item, index) => (
                    imageHidden ?
                      <RankingHiddenImage key={index} />
                      : <RankingImage
                        onClick={clickSelectImage}
                        key={`${item.id}-${index}-following`}
                        image={item}
                      />
                  ))}
                </div>
              </InfiniteScroll>
            </TabsContent>
          )}
          {latestItems && latestImagesResponseData && (
            <TabsContent value="Latest">
              <InfiniteScroll
                key={`infinite-scroll-latest-${new Date().getTime()}`}
                dataLength={latestItems.length}
                next={latestImagesResponseData.loadMore}
                hasMore={latestImagesResponseData.hasMore}
                loader={<div>...loading</div>}
              >
                <div className="grid grid-cols-3 w-full max-w-5xl mx-auto px-4">
                  {latestItems.map((item, index) => (
                    imageHidden ?
                      <RankingHiddenImage key={index} />
                      : <RankingImage
                        onClick={clickSelectImage}
                        key={`${item.id}-${index}-latest`}
                        image={item}
                      />
                  ))}
                </div>
              </InfiniteScroll>
            </TabsContent>
          )}
        </Tabs>
      </div>
    </div>
  );
};

export default RankingList;
