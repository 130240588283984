"use client";

import { useSession } from "next-auth/react";
import InfiniteScroll from "react-infinite-scroller";
import {
  ImageGetRequest,
  ImageOrderCol,
  ImageResponseData,
  OrderBy,
} from "@/restapi/image";
import { FC, useEffect, useState } from "react";

import { useOrderByImages } from "@/hooks/image/useOrderByImages";
import { RANKING_TYPE, RankingTypeConst } from "@/restapi/ranking";
import FullScreenImage from "./FullScreenImage";
import { useImageFind } from "@/hooks/image/useImageFind";
import { useGroupContext } from "@/context/GroupContextProvider";
import { GroupDataResponse } from "@/restapi/group";
import { RankingTypeValue } from "@/components/Ranking";

/**
 * 全画面表示用のコンポーネント
 * @param param0
 * @returns
 */
const FullScreenList: FC<{
  selectedImageId: string;
  images: ImageResponseData[];
  imageOrderCol: ImageOrderCol;
  userUuid?: string;
  isNotScored?: boolean;
  orderBy: OrderBy;
  isFollwoing?: boolean;
  rankingType: RankingTypeValue;
  pathname?: string | null;
  group?: GroupDataResponse;
}> = ({
  images,
  selectedImageId,
  imageOrderCol,
  orderBy,
  isNotScored,
  userUuid,
  rankingType,
  isFollwoing = false,
  pathname: groupMypagePathname,
  group = undefined,
}) => {
    const { data: session } = useSession();
    const user = session?.user;
    const userId = user?.id ?? "";

    // グループ機能のマイページから遷移してきた場合はこのモーダルから戻るときにマイページのパス名が必要なので保存
    const { setPrevPathname } = useGroupContext();

    // useEffectスクロール時に重複を削除
    const uniqueImages = images.filter(
      (img, index, self) => self.findIndex((item) => item.uuid === img.uuid) === index
    );

    // 初期状態として渡された items をセット
    const [items, setItems] = useState<ImageResponseData[]>(uniqueImages);

    const request: ImageGetRequest = {
      authUuid: userId,
      userUuid: userUuid ?? null,
      orderCol: imageOrderCol,
      orderBy,
      isNotScored: isNotScored ?? false,
      isFollwoing: isFollwoing ?? false,
      groupId: group?.id ?? null,
    };

    const {
      items: _items,
      isError,
      isLoading,
      loadMore,
      hasMore,
      isValidating,
    } = useOrderByImages(request, items.length);

    const currentTime = new Date().toISOString();
    const { item: latestImage, isLoading: latestImageLoading, isError: latestImageError } = useImageFind({
      where: "created_at",
      whereCol: currentTime,
      operator: "=",
    })

    // 最新の投稿画像より投稿時刻が新しい場合は追加
    useEffect(() => {
      if (!_items) return;

      if (!latestImageLoading && !latestImageError && latestImage && 0 < _items.length) {
        if (!_items) return;

        const image = _items[0];
        // 最新か平均点なしの場合のみ追加
        if (latestImage.timestamp > image.timestamp && image.uuid !== latestImage.uuid
          && (rankingType === RANKING_TYPE.LATEST || rankingType === RANKING_TYPE.NOT_SCORED)) {
          setItems(pre => [latestImage, ...pre]);
        }
      }
    }, [latestImage]);

    // 初回のみグループパス名が存在する場合は設定
    useEffect(() => {
      if (groupMypagePathname) {
        setPrevPathname(groupMypagePathname)
      }
    }, []);

    // 遷移先で途中からスクロールを開始する場合の無限スクロールの配列にitemsを追加する処理
    useEffect(() => {
      if (!isLoading) {
        if (!_items) return;

        // 重複しないように新しいアイテムを追加
        setItems((prevItems) => {
          const newItems = _items.filter(
            (newItem) =>
              !prevItems.some((prevItem) => prevItem.uuid === newItem.uuid)
          );
          return [...prevItems, ...newItems];
        });
      }
    }, [_items]);

    // 選択された画像までスクロール
    useEffect(() => {
      if (selectedImageId && items.length > 0) {
        const targetIndex = items.findIndex(
          (img) => img.uuid === selectedImageId
        );
        if (targetIndex >= 0) {
          const obj = document.getElementById(`image-${targetIndex}`);
          if (obj) {
            obj.scrollIntoView({ behavior: "smooth" });
          }
        }
      }
    }, []);


    if (isError) {

      return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-black text-red-600 p-4">
          <div className="mb-10 text-center"></div>
        </div>
      );
    }

    return (
      <div className="pc-container bg-black min-h-screen">
        <div className="slide" id="scrollable-div">
          <InfiniteScroll
            pageStart={0}
            loadMore={loadMore}
            hasMore={hasMore && !isValidating}
            loader={
              <div key={0} className="loader">
                Loading ...
              </div>
            }
            useWindow={false}
          >
            {items.map((img, index) => (
              <div key={img.uuid} id={`image-${index}`} className="snap-item"
                data-id={img.id}
              >
                <FullScreenImage key={`${img.uuid}-${index}`} image={img} />
              </div>
            ))}
          </InfiniteScroll>
        </div>
      </div>
    );
  };

export default FullScreenList;
