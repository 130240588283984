import UserActiveIcon from "../icons/UserActiveIcon";
import { useNotifyGetNewPostedImage } from "@/hooks/notification/useNotification";
import { NOTIFICATION_TYPE_VALUE } from "@/restapi/notification";
import { useSession } from "next-auth/react";

export const UserActivLink = () => {
	const { data: session } = useSession();
	const user = session?.user;
	const {
		item,
		isLoading,
		isError,
	} = useNotifyGetNewPostedImage({
		userId: user?.id ?? '',
		groupId: null,
		notificationType: NOTIFICATION_TYPE_VALUE.NEW_POSTED_GROUP_IMAGE,
	}
	);

	if (isLoading || isError) {
		return <UserActiveIcon />
	}

	if (!item || 1 > item.count) {
		return <UserActiveIcon />
	}

	return <UserActiveIcon />;
};

export default UserActivLink;