import { BackendURLContantas, URLConstants } from "@/constants/URLConstants";
import { axios } from "@/lib/axios";

import { AxiosResponse } from "axios";
import { UserIconFile } from "../user";
import { FollowingImagesRequest } from "../follower";
import { QueryOperator } from "@/constants/AppConstants";

// ソートカラム
export type ImageOrderCol =
  | "average_score"
  | "total_score"
  | "posts"
  | "not_scored"
  | "following"
  | "timestamp";

export type OrderBy = "desc" | "asc";

export type ImageRankingRequest = {
  myUserId?: string | null;
  userId?: string | null;
  groupId?: number | null;
};

export type ImageDeleteEventResponse = {
  imageId: string;
  posts: number;
  averageScore: number | null;
  totalScore: number;
};

export type ImagePostEventResponse = {
  image: ImageResponseData;
  posts: number;
  averageScore: number | null;
  totalScore: number;
};

export type ImageDeleteRequest = {
  uuid: string;
};

export type ImagePostRequest = {
  city: string;
  country: string;
  fileName: string;
  fileURL: string;
  latitude: number;
  longitude: number;
  province: string;
  suburb: string;
  timestamp: number;
  userId: string;
  score: number;
  groupId: number | null;
};

export type ImageFile = {
  id: number;
  fileUrl: string;
  fileName: string;
  createdAt: string;
  updatedAt: string;
};

type UserProfile = {
  id: number;
  userIconFileId: number | null;
  email: string;
  uuid: string;
  testimonials: string | null;
  hideName: boolean | null;
  name: string;
  userIconFile: UserIconFile;
  createdAt: string;
  updatedAt: string;
};

export type ImageResponseData = {
  id: number;
  userUuid: string;
  imageFileId: number;
  scoreExists: boolean;
  myScore: number;
  uuid: string;
  averageScore: number | null;
  totalScore: number | null;
  posts: number | null;
  city: string;
  country: string;
  province: string;
  suburb: string;
  latitude: number;
  longitude: number;
  timestamp: number;
  createdAt: string;
  updatedAt: string;
  imageFile: ImageFile;
  userProfile: UserProfile;
  following: boolean;
};


export type ImageResponse = {
  data: ImageResponseData[];
  currentPage: number;
  lastPage: number;
  total: number;
};

type ImagePostResponse = {
  message: string;
  ok: boolean;
};

type ImageTotalResponse = {
  total: number | null;
};

type ImageAvgResponse = {
  avg: number | null;
};

type ImageDeleteResponse = {
  message: string;
  ok: boolean;
  data: {
    fileName: string;
    userUuid: string;
    imageUuid: string;
  };
};

export type ImageGetRequest = {
  userUuid: string | null;
  authUuid: string | null;
  orderCol: ImageOrderCol;
  orderBy: OrderBy;
  isNotScored: boolean;
  isFollwoing: boolean;
  groupId?: number | null;
};

export type ImageShowRequest = {
  imageUuid: string;
  authUuid: string;
};

type ImageWhere = 'created_at';
export type ImageFindRequest = {
  where: ImageWhere;
  whereCol: string;
  operator: QueryOperator;
};

export type ImageExcludeFromGroupRequest = {
  imageId: number;
  groupId: number;
}

export type ImageExcludeFromGroupResponse = {
  message: string;
}


const validImageTotalResponse = (arg: unknown): boolean => {
  const res = arg as ImageTotalResponse;

  return typeof res.total === "number" || typeof res.total === null;
};

const validImageAvgResponse = (arg: unknown): boolean => {
  const res = arg as ImageAvgResponse;
  return typeof res.avg === "number" || res.avg === null;
};


export type ImagesResponseData = {
  items?: ImageResponseData[];
  isLoading: boolean;
  isError: boolean;
  loadMore: () => void;
  hasMore: boolean
  isValidating: boolean;
}



export type Chat = {
  id: number;
  message: string;
  createdAt: string;
  updatedAt: string;
};

export type Chats = Chat[];
// 画像情報のLaravel用API
export const ImageAPI = {
  post: async (
    imagePostRequest: ImagePostRequest
  ): Promise<ImagePostResponse> => {
    const { data } = await axios.post<
      ImagePostRequest,
      AxiosResponse<ImagePostResponse>
    >(BackendURLContantas.IMAGE.POST.PATH_NAME, imagePostRequest);

    return data;
  },
  delete: async (
    imageDeleteRequest: ImageDeleteRequest
  ): Promise<ImageDeleteResponse> => {
    const { data } = await axios.post<
      ImageDeleteRequest,
      AxiosResponse<ImageDeleteResponse>
    >(BackendURLContantas.IMAGE.DELETE.PATH_NAME, imageDeleteRequest);

    return data;
  },
  total: async (imageId: string) => {
    const { data } = await axios.get<
      unknown,
      AxiosResponse<ImageTotalResponse>
    >(`${BackendURLContantas.IMAGE.TOTAL.PATH_NAME}/${imageId}`);

    if (!validImageTotalResponse(data)) {
      throw Error("Failed to get total score.");
    }

    return data;
  },
  avg: async (imageId: string) => {
    const { data } = await axios.get<unknown, AxiosResponse<ImageAvgResponse>>(
      `${BackendURLContantas.IMAGE.AVG.PATH_NAME}/${imageId}`
    );

    if (!validImageAvgResponse(data)) {
      throw Error("Failed to get average score.");
    }

    return data;
  },

  get: async () => {
    const { data } = await axios.get<unknown, AxiosResponse<Chat[]>>(`chat`);

    return data;
  },
  show: async (request: ImageShowRequest) => {
    const { data } = await axios.post<
      ImageGetRequest,
      AxiosResponse<ImageResponseData>
    >(`${BackendURLContantas.IMAGE.SHOW.PATH_NAME}`, request);

    return data;
  },
  find: async (request: ImageFindRequest) => {
    const { data } = await axios.post<
      ImageFindRequest,
      AxiosResponse<ImageResponseData>
    >(`${BackendURLContantas.IMAGE.FIND.PATH_NAME}`,
      request,
    );

    return data;
  },

  index: async (request: ImageGetRequest, page: number = 1) => {
    const { data } = await axios.post<
      ImageGetRequest,
      AxiosResponse<ImageResponse>
    >(`${BackendURLContantas.IMAGE.PATH_NAME}?page=${page}`, request);

    return data;
  },

  following: async (request: FollowingImagesRequest, page: number = 1) => {
    const { data } = await axios.post<
      ImageRankingRequest,
      AxiosResponse<ImageResponse>
    >(
      `${BackendURLContantas.IMAGE.FOLLOWING.PATH_NAME}?page=${page}`,
      request
    );

    return data;
  },
  excludeFromGroupRequest: async (request: ImageExcludeFromGroupRequest) => {
    const { data } = await axios.post<
      ImageExcludeFromGroupRequest,
      AxiosResponse<ImageExcludeFromGroupResponse>
    >(
      `${BackendURLContantas.IMAGE.EXCLUDE_FROM_GROUP.PATH_NAME}`,
      request
    );

    return data;
  }
};
