import { axios } from "@/lib/axios";
import { AxiosResponse } from "axios";
import { BackendURLContantas } from "@/constants/URLConstants";

export const NOTIFICATION_TYPE_VALUE = {
  SCORING_HISTORY: 1,
  NEW_POSTED_IMAGE: 2,
  NEW_POSTED_GROUP_IMAGE: 3,
  GOURP_NEW_MEMBER_PENDING: 4,
  GROUP_ADD_NEW_MEMBER: 5,
} as const;

type NOTIFICATION_TYPE = typeof NOTIFICATION_TYPE_VALUE[keyof typeof NOTIFICATION_TYPE_VALUE];

export type NotificationEvent = {
  count: number;
  userUuid: string;
};

export type NotficationEventResponse = {
  notifications: NotificationEvent[] | undefined;
};

export type NotificationRequest = {
  userUuid: string;
  fromUserUuid: string;
};

export type NotificationGetRequest = {
  notificationType: NOTIFICATION_TYPE;
  userId: string;
  groupId: null | number;
}

export type NotificationPostResponse = {
  message: string;
};

export type NotificationGetResponse = {
  count: number;
};

export type NotificationReadGetRequest = {
  notificationType: NOTIFICATION_TYPE;
  userId: string;
  groupId?: number | null;
};

export type NotificationReadGetResponse = {
  message: string;
};

export const NotificationAPI = {
  postNewPostedImage: async (request: NotificationRequest) => {
    const { data } = await axios.post<
      NotificationRequest,
      AxiosResponse<NotificationPostResponse>
    >(`${BackendURLContantas.NOTIFICATION.NEW_POSTED_IMAGE}`, request);

    return data;
  },
  postScoringHistory: async (request: NotificationRequest) => {
    const { data } = await axios.post<
      NotificationRequest,
      AxiosResponse<NotificationPostResponse>
    >(`${BackendURLContantas.NOTIFICATION.SCORING_HISTORY}`, request);

    return data;
  },

  getNewPostedImage: async (request: NotificationGetRequest) => {
    const { data } = await axios.post<
      NotificationGetRequest,
      AxiosResponse<NotificationGetResponse>
    >(
      `${BackendURLContantas.NOTIFICATION.NEW_POSTED_IMAGE.GET.PATH_NAME}`,
      request,
    );

    return data;
  },
  getScoringHistory: async (userId: string) => {
    const { data } = await axios.get<
      NotificationRequest,
      AxiosResponse<NotificationGetResponse>
    >(
      `${BackendURLContantas.NOTIFICATION.SCORING_HISTORY.PATH_NAME}/${userId}`
    );

    return data;
  },

  readNewPostedImage: async (request: NotificationReadGetRequest) => {
    const { data } = await axios.post<
      NotificationReadGetRequest,
      AxiosResponse<NotificationReadGetResponse>
    >(
      `${BackendURLContantas.NOTIFICATION.NEW_POSTED_IMAGE.READ.PATH_NAME}`,
      request
    );

    return data;
  },
  readScoringHistory: async (userId: string) => {
    const { data } = await axios.get<
      NotificationRequest,
      AxiosResponse<NotificationReadGetResponse>
    >(
      `${BackendURLContantas.NOTIFICATION.SCORING_HISTORY.READ.PATH_NAME}/${userId}`
    );

    return data;
  },
};
