"use client";

import { MouseEvent, useState } from "react";
import { usePathname } from "next/navigation";
import ImageUploadModal from "@/components//Pict/ImageUploadModal";
import { useAppContext } from "@/context/AppContextProvider";

import CrownActiveIcon from "@/components//icons/CrownActiveIcon";
import CrownDeactiveIcon from "@/components/icons/CrownDeactiveIcon";
import PlusDivactiveIcon from "./icons/PlusDivactiveIcon";
import { URLConstants } from "@/constants/URLConstants";
import UserActivLink from "./mypage/UserActivLink";
import UserDeActiveLink from "./mypage/UserDeActiveLink";
import Link from "next/link";
import HomeLink from "./home/link/HomeLink";
import { GroupFooterLink } from "./group/links/GroupFooterLink";
import "@/components/footer.css";
import { useSession } from "next-auth/react";

const Footer = () => {
  const {
    setSettingMenuProps,
    visableMode,
    isMobile,
    isModalOpen,
    imageDisplayMode,
    setImageDisplayMode,
  } = useAppContext();
  const [isImgHistoryHovered, setIsImgHistoryHovered] = useState(false);
  const [isCrownHovered, setIsCrownHovered] = useState(false);
  const pathname = usePathname();
  const { data: session } = useSession();
  const user = session?.user;

  const handleClick = (e: MouseEvent<HTMLAnchorElement, globalThis.MouseEvent>, url: string) => {
    e.preventDefault();
    e.stopPropagation();

    setSettingMenuProps({
      isMeneuOpen: false,
    });
    if (imageDisplayMode === "FullScreenMode") {
      // リンクを踏むと画像一覧をデフォルトの3列表示に戻す
      if (typeof window !== "undefined") {
        window.location.href = pathname;
      }
    }
    else {
      if (typeof window !== "undefined") {
        window.location.href = url;
      }
    }
  };

  return (
    <div
      className={`bg-black fixed bottom-0 left-1/2 transform -translate-x-1/2 w-full min-w-screen-sm wrapFootNaviArea ${visableMode.isShow ? "fade-in" : "fade-out"
        }`}
      style={{ zIndex: 200 }}
    >

      <ul className="flex justify-around bg-opacity-50 py-4 z-50 footNaviArea">
        <li className="flex flex-col items-center footNavi footNavi01">
          <HomeLink />
        </li>
        <li className="flex flex-col items-center footNavi footNavi02">
          <Link
            href={`${user ? URLConstants.user.mypage.pathname : "/signin"}`}
            className={`${isModalOpen ? "linkDisabled" : "cursor-pointer"}`}
            onClick={(e) => handleClick(e, URLConstants.user.mypage.pathname)}
            onMouseEnter={() => setIsImgHistoryHovered(true)}
            onMouseLeave={() => setIsImgHistoryHovered(false)}
          >
            {URLConstants.user.mypage.pathname === pathname ||
              (isImgHistoryHovered && !isMobile) ? (
              <UserActivLink />
            ) : (
              <UserDeActiveLink />
            )}
          </Link>
        </li>

        <li
          className="flex flex-col items-center footNavi footNavi03"
        >
          {user ? (
            <ImageUploadModal />
          ) : (
            <Link
              onClick={(e) => handleClick(e, "/signin")}
              className={`${isModalOpen ? "linkDisabled" : "cursor-pointer"}`}
              href="/signin"
            >
              <PlusDivactiveIcon className="w-6 h-6" />
            </Link>
          )}
        </li>
        <li className="flex flex-col items-center footNavi footNavi04">
          <GroupFooterLink isModalOpen={isModalOpen} user={user} />
        </li>
        <li className="flex flex-col items-center  footNavi footNavi05">
          <Link
            href={URLConstants.ranking.pathname}
            className={`${isModalOpen ? "linkDisabled" : "cursor-pointer"}`}
            onClick={(e) => handleClick(e, URLConstants.ranking.pathname)}
            onMouseEnter={() => setIsCrownHovered(true)}
            onMouseLeave={() => setIsCrownHovered(false)}
          >
            {URLConstants.ranking.pathname === pathname ||
              (isCrownHovered && !isMobile) ? (
              <CrownActiveIcon />
            ) : (
              <CrownDeactiveIcon />
            )}
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default Footer;
