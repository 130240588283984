import React from 'react';

export const Checkbox: React.FC<{ label?: string; onClickCallback: (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => void; checked: boolean }> = ({ onClickCallback, checked, label }) => {

  return (
    <label className="inline-flex items-center cursor-pointer transition duration-200 p-1">
      <input
        type="checkbox"
        onClick={onClickCallback}
        checked={checked}
        className="form-checkbox h-5 w-5 text-gray-600 cursor-pointer custom-checkbox"
      />
      <span className='inline-block px-4 '>{label} </span>
      <style jsx>{`
        input[type="checkbox"].custom-checkbox {
          appearance: none;
          -webkit-appearance: none;
          -moz-appearance: none;
          background-color: transparent;
          border: 2px solid #fff;
          border-radius: 4px;
          width: 1.25rem;
          height: 1.25rem;
          cursor: pointer;
          position: relative;
        }

        input[type="checkbox"].custom-checkbox:checked {
          background: linear-gradient(to right, #404040, #f5f5f5);
        }

        input[type="checkbox"].custom-checkbox:checked::after {
          content: "✔";
          color: white;
          font-size: 1rem;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      `}</style>
    </label>
  );
}

export default Checkbox;
