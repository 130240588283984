"use client";

import { useAppContext } from "@/context/AppContextProvider";
import Link from "next/link";
import Image from "next/image";
import ImageIconComponent from "@/components/icons/ImageIconComponent";
import { User } from "next-auth";
import { useUserGetProfile } from "@/hooks/user/useUserGetProfile";
import { useTranslation } from "@/lib/react/i18n";
import { URLConstants } from "@/constants/URLConstants";

const FullScreenImageHeader = ({ user, orderBy = 'new' }: { user: User, orderBy?: 'new' | 'following' }) => {
  const { t, loading: tLoading } = useTranslation();
  const { visableMode } = useAppContext();
  const { data: userProfileData, isLoading } = useUserGetProfile({
    userUuid: user.id,
  })

  if (isLoading || tLoading) {
    return <></>;
  }

  return (
    <div
      className={`fixed min-w-screen-sm w-full  py-2 bg-black ${visableMode.isShow ? "fade-in" : "fade-out"}`}
      style={{ zIndex: 500 }}
    >
      <div className="flex justify-between">
        <div
          className={`z-50 w-1/2 max-w-32`}
        >
          <Link className="text-white" href="/">
            <div className="">
              <Image
                alt="picsle logo"
                src="/picsle_logo.svg"
                width={300}
                height={300}
                style={{ width: "100%", height: "auto" }}
                sizes="(max-width: 768px) 100vw, 300px"
                fill={false}
                priority={false}
              />
            </div>
          </Link>
        </div>
        <div
          className={`mr-2 ${visableMode.isShow ? "fade-in" : "fade-out"}`}>
          <ImageIconComponent
            iconType={'User'}
            borderEnable={false}
            plusDisabledIcon={true}
            fileURL={userProfileData?.userIconFile?.fileUrl ?? ''}
          />
        </div>
      </div>

      <div className={`text-white flex justify-center my-2`}
      >
        <Link className={`text-xs md:text-xl mx-2 ${orderBy === 'new' ? 'border-b-2 border-gray-100 rounded-none' : ''}`}
          href={`${URLConstants.image.latest.pathname}`}
        >
          {t('New')}
        </Link>
        <Link className={`text-xs md:text-xl mx-2 ${orderBy === 'following' ? 'border-b-2 border-gray-100 rounded-none' : ''}`}
          href={`${URLConstants.image.following.pathname}`}
        >
          {t('following')}
        </Link>
      </div>
    </div >
  );
};

export default FullScreenImageHeader;
