/**
 *  アプリケーション共通で使用する定数
 */
const APP_CONSTANTS = {
  // APIの定期更新間隔
  REFETCH_INTERVAL_TIME: 3000,
}

export type QueryOperator = '=' | '<=' | '>=' | '!=';

export const BREAK_POINT_CONSTANTS = {
  SM: 576,
  MD: 768,
  LG: 1024,
  XSS: 352,
}

export default APP_CONSTANTS;