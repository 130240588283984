"use client";

import React, { useEffect, useState } from "react";
import Image from "next/image";
import { useSession } from "next-auth/react";
import ScoreModalButton from "@/components/ScoreModalButton";
import { useAppContext } from "@/context/AppContextProvider";
import { bizUDPGothicBold } from "@/lib/utils/fonts";
import { ImageResponseData } from "@/restapi/image";
import { ScoreData } from "@/restapi/score";
import { ImageAverageModal } from "@/components/Pict/ImageAverageModal";
import ImageIconComponent from "@/components/icons/ImageIconComponent";
import { useGroupGetWithImage } from "@/hooks/group/useGroupGetWithImage";
import { GroupImageDeleteButton } from "@/components/group/buttons/GroupImageDeleteButton";
import { usePathname } from "next/navigation";
import { URLConstants } from "@/constants/URLConstants";
import "@/components/image/full-screen/fullScreenImage.css";


const FullScreenImage: React.FC<{ image: ImageResponseData; }> = ({ image }) => {
  const { data: session } = useSession();
  const user = session?.user;
  const [scoreExists, setScoreExists] = useState(image.scoreExists);
  const pathname = usePathname();
  const isGroupMaypage = /^\/group\/mypage\/[^/]+$/.test(pathname);

  const {
    visableMode,
    setVisableMode,
    settingMenuProps,
    setSettingMenuProps,
  } = useAppContext();

  const [scoreData, setScoreData] = useState<ScoreData>({
    posts: image.posts ?? 0,
    averageScore: image.averageScore,
    totalScore: image.totalScore ?? 0,
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  const { data: group, isError, isLoading } = useGroupGetWithImage({
    imageId: image.id,
    userId: user?.id ?? '',
  });

  useEffect(() => {
    if (group && group.adminIds) {
      const adminId = group.adminIds.find(id => id === (user?.id ?? ''));
      setIsAdmin(adminId ? true : false);
    }
  }, [group, isLoading]);

  // // 平均点リアルタイム反映
  // const echo = useEcho();
  // useEffect(() => {
  //   if (echo) {
  //     echo
  //       .channel(`channel-score-post-${image.uuid}`)
  //       .listen("ScorePostEvent", (response: ScorePostEventResponse) => {
  //         const {averageScore, totalScore, posts, userId: _userId } = response;
  //         setScoreExists(true);
  //         setScoreData({
  //           averageScore,
  //           totalScore,
  //           posts,
  //         });
  //       });
  //   }
  // }, [echo]);

  const handleClickAverage = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.preventDefault();
    e.stopPropagation();
    setIsModalOpen(true);
  };

  const handleToggleVisibility = () => {
    if (settingMenuProps.isMeneuOpen) {
      setSettingMenuProps({
        isMeneuOpen: false,
      });
      return;
    }

    setVisableMode({
      isShow: !visableMode.isShow,
    });
  };

  const handleClickGroupMypageLink = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();

    if (group && typeof window !== "undefined") {
      window.location.href = `${URLConstants.group.mypage.pathname}/${group.id}`;
    }
  }

  const handleClickUserMypageLink = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    if (typeof window !== "undefined") {
      window.location.href = `${URLConstants.user.mypage.pathname}/${image.userProfile.uuid}`;
    }
  }

  if (isLoading) {
    return <></>;
  }
  if (isError) {
    return <></>;
  }

  return isModalOpen ? (
    <ImageAverageModal
      isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen}
      image={image}
    />
  ) : (
    <div className="relative w-100 h-screen" onClick={handleToggleVisibility}>
      <div className="text-with-outline-shadow average-score">
        {typeof scoreData.averageScore === "number" &&
          scoreData.posts !== null && (
            <div
              onClick={handleClickAverage}
              className={`mt-20 cursor-pointer avgScoreBox ${visableMode.isShow ? "fade-in" : "fade-out"
                }`}
            >
              <span className="avgScore">{scoreData.averageScore}</span>
              <br />
              <span className="avgTxt">avg.</span>
            </div>
          )}
      </div>
      <Image
        className={`${visableMode.isShow ? "fade-in" : "fade-out"} object-cover`}
        alt={image.imageFile.fileName}
        src={image.imageFile.fileUrl}
        fill
        priority
      />
      <Image
        className={`${visableMode.isShow ? "fade-out" : "fade-in"} object-contain`}
        alt={image.imageFile.fileName}
        src={image.imageFile.fileUrl}
        fill
        priority
      />

      <div className="mb-16">
        {user && (
          <>
            {group && group.id !== undefined &&
              <div
                className={`
                      absolute bottom-20 right-20
                      ${visableMode.isShow ? "fade-in" : "fade-out"}`}
              >
                <div
                  className="cursor-pointer"
                  onClick={handleClickGroupMypageLink}
                >
                  <ImageIconComponent
                    fileURL={group?.iconFileUrl ?? ''}
                    plusDisabledIcon={true}
                    iconType={'Group'}
                  />
                </div>
              </div>
            }
            <div
              className={`
                  absolute bottom-20 right-4 
                  ${visableMode.isShow ? "fade-in" : "fade-out"}`}
            >
              <div
                className="cursor-pointer"
                onClick={handleClickUserMypageLink}
              >
                <ImageIconComponent
                  fileURL={image.userProfile.userIconFile?.fileUrl ?? ''}
                  plusDisabledIcon={true}
                />
              </div>
            </div>
            {group && (isAdmin || image.userProfile.uuid === user.id) && isGroupMaypage &&
              <div className="absolute top-28 right-4">
                <GroupImageDeleteButton image={image} group={group} />
              </div>
            }
          </>
        )}

        {!scoreExists && (
          <ScoreModalButton
            setScoreExists={setScoreExists}
            imageData={image}
            setScoreData={setScoreData} />
        )}
      </div>
      <div className="scoreInfoArea">
        <div
          className={`opacity-65 score-number ${bizUDPGothicBold.className} ${visableMode.isShow ? "fade-in" : "fade-out"
            }`}
        >
          <span className="opacity-65">{image.myScore}</span>
        </div>
        <>
          <div
            className={`mapMark"><LocateIcon className="text-with-outline-shadow locate-icon ${visableMode.isShow ? "fade-in" : "fade-out"
              }`}
          ></div>
          {/* <div className="cityBtn">
            <span
              className={`text-white text-with-outline-shadow city-name ${visableMode.isShow ? "fade-in" : "fade-out"
                }`}
            >
              {image.city && image.province
                ? `${image.province} ${image.city}`
                : image.country}
            </span>
          </div>
          <div className="accountBtn">
            {(image.userProfile.name ?? null !== null) &&
              11 < image.userProfile.name.length ? (
              <span
                className={`text-white text-with-outline-shadow account-name ${visableMode.isShow ? "fade-in" : "fade-out"
                  }`}
              >
                @{`${image.userProfile.name.slice(0, 11)}...`}
              </span>
            ) : (
              <span
                className={`text-white text-with-outline-shadow account-name ${visableMode.isShow ? "fade-in" : "fade-out"
                  }`}
              >
                @{image.userProfile.name}
              </span>
            )}
          </div> */}
        </>
      </div>
    </div >
  );
};

export default FullScreenImage;
