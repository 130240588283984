'use client';
import React, { useContext, createContext, useState, ReactNode } from "react";
import { User } from "next-auth";
import { GroupDataResponse } from "@/restapi/group";


type HeaderSettingContextProps = {
  user: User | undefined;
  setUser: (user: User | undefined) => void;
  currentGroup: GroupDataResponse | null;
  setCurrentGroup: (group: GroupDataResponse | null) => void;
  prevPathname: string | null;
  setPrevPathname: (pathname: string | null) => void;
};

// コンテキストを作成
const HeaderContext = createContext<HeaderSettingContextProps>({
  user: undefined,
  setUser: (user: User | undefined) => { },
  currentGroup: null,
  setCurrentGroup: (group: GroupDataResponse | null) => { },
  prevPathname: null,
  setPrevPathname: (pathname: string | null) => { },
});

// プロバイダーコンポーネント
export const HeaderSettingContextProvider: React.FC<{ children?: ReactNode }> = ({
  children,
}) => {
  const [user, setUser] = useState<User | undefined>(undefined);
  const [prevPathname, setPrevPathname] = useState<string | null>(null);
  const [currentGroup, setCurrentGroup] = useState<GroupDataResponse | null>(null);

  return (
    <HeaderContext.Provider
      value={{
        user,
        setUser,
        currentGroup,
        setCurrentGroup,
        prevPathname,
        setPrevPathname,
      }}
    >
      {children}
    </HeaderContext.Provider>
  );
};

// コンテキストを使用するためのフック
export const useHeaderSettingContext = (): HeaderSettingContextProps =>
  useContext<HeaderSettingContextProps>(HeaderContext);

export default HeaderSettingContextProvider;
