"use client";

import { User } from "next-auth";
import { bizUDPGothic } from "@/lib/utils/fonts";
import { useGroupContext } from "@/context/GroupContextProvider";
import { usePathname } from "next/navigation";
import { useEffect } from "react";
import Footer from "./Footer";
import { useSession } from "next-auth/react";
import GuestHeader from "./organisms/header/GuestHeader";
import { useAppContext } from "@/context/AppContextProvider";
import FullScreenImageHeader from "./organisms/header/FullScreenImageHeader";
import { adjustViewport } from "@/lib/utils";

export default function App({
  children,
}: Readonly<{
  children: React.ReactNode;
  user: User | undefined;
}>) {

  // 準備処理 352px未満は画面の文字とかレイアウトを縮小表示
  adjustViewport();

  const pathname = usePathname();
  const { setCurrentGroup } = useGroupContext();
  const { imageDisplayMode } = useAppContext();
  const { data: session } = useSession();
  const user = session?.user;
  const groupMypageRegex = /^\/group\/mypage\/\d+$/;

  useEffect(() => {
    // `/group/`で始まるパスにマッチしない場合はグループページでないので初期化する
    if (!/^\/group\//.test(pathname)) {
      setCurrentGroup(null);
    }
  }, [pathname])

  return (
    <body
      className={`${bizUDPGothic.className} flex flex-col min-h-screen bg-black`}
    >
      <div className={`flex flex-col px-4 pc-container ${groupMypageRegex.test(pathname) ? 'bg-purple-500' : ''}`}>
        {!user && (
          <GuestHeader />
        )}
        {user && imageDisplayMode === 'FullScreenMode' && (
          <FullScreenImageHeader user={user} />
        )}
        <main>{children}</main>
        <Footer />
      </div>
    </body>
  );
}
