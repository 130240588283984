import { ImageResponseData } from "@/restapi/image";
import { ScorePostEventResponse, UserScoreRequest } from "@/restapi/user";
import { useAppContext } from "@/context/AppContextProvider";
import useEcho from "@/hooks/useEcho";
import { useUserScore } from "@/hooks/user/useUserScore";
import { useTranslation } from "@/lib/react/i18n";
import { useSession } from "next-auth/react";
import { usePathname } from "next/navigation";
import React, { Dispatch, SetStateAction, useState } from "react";
import { ScoreData } from "@/restapi/score";

type Props = {
  onClose: () => void;
  imageResponseData: ImageResponseData;
  setScoreExists: (value: boolean) => void;
  setScoreData: Dispatch<SetStateAction<ScoreData>>;
}


const useInputModal = ({
  onClose,
  imageResponseData,
  setScoreExists: setScoreExists,
  setScoreData,
}: Props) => {
  const pathname = usePathname();
  const [error, setError] = useState("");
  const [score, setScore] = useState<number | null>(0);
  const { setIsUploading, isUploading } = useAppContext();
  const { submitScore } = useUserScore();
  const { data: session } = useSession();
  const [scorerNameHidden, setScorerNameHidden] = useState(false);
  const { t } = useTranslation();

  const user = session?.user;
  const handleCommentChange = (e: any) => {
    setScore(e[0]);
  };

  const handleNameHiddenCheck = (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    e.stopPropagation();

    const target = e.target as HTMLInputElement;
    if (target.type === 'checkbox') {
      setScorerNameHidden(target.checked);
    }
  };

  const handleCancel = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    setScoreData(pre => ({
      ...pre,
      averageScore: imageResponseData.averageScore,
    }));
    onClose();
  };

  const handleScoreInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    const inputEvent = e.nativeEvent as InputEvent;
    if ((inputEvent as any).inputType === "deleteContentBackward") {
      setScore(null);
    } else {
      const value = parseInt(e.target.value, 10);
      if (!isNaN(value) && value >= 0 && value <= 100) {
        setScore(value);
      }
    }
  };

  const handleCommentSubmit = async () => {
    let href = '';
    setIsUploading(true);
    if (user && user.id) {
      // laravel側のAPIにスコア情報を登録 → 平均点・合計点など計算結果が返却される
      const userScoreRequest: UserScoreRequest = {
        imageId: imageResponseData.uuid,
        score: score ?? 0,
        ownerFlg: false,
        userId: user.id,
        scorerNameHidden,
      };
      try {
        setScoreExists(true);

        await submitScore(userScoreRequest);

        // 平均点更新
        setScoreData(pre => ({
          ...pre,
          averageScore: Math.floor((pre.totalScore + (score ?? 0)) / ((pre.posts + 1)))
        }));


      } catch (error: any) {
        if (error.response && error.response.status === 409) {
          setScoreExists(true);
          alert(t("Already scored"));
        }
        else if (error.response && error.response.status === 404) {
          setScoreExists(true);
          alert(t("There are no images to score."));
          href = pathname;
        }
        else {
          setScoreExists(true);
          href = pathname;
          alert(t("There are no images to score."));
        }
        onClose();
      } finally {
        // モーダルを閉じる
        onClose();
        setIsUploading(false);
      }
    }
    setIsUploading(false);
  }

  return {
    error,
    handleCancel,
    isUploading,
    setIsUploading,
    score,
    handleScoreInputChange,
    scorerNameHidden,
    handleNameHiddenCheck,
    handleCommentChange,
    handleCommentSubmit,
    t,
  };
};

export default useInputModal;
