import React, { Dispatch, FC, SetStateAction, useEffect } from "react";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
} from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { cn } from "@/lib/utils";
import { Slider } from "@/components/ui/slider";
import useInputModal from "./hooks/useInputModal";
import { ImageResponseData } from "@/restapi/image";
import DeleteCheckbox from "../History/inputs/DeleteCheckbox";
import Checkbox from "./checkbox/Checkbox";
import { ScoreData } from "@/restapi/score";


type Props = {
  setScoreData: Dispatch<SetStateAction<ScoreData>>;
  imageResponseData: ImageResponseData;
  setScoreExists: (value: boolean) => void;
  onClose: () => void;
}

const ScoreInputModal: FC<Props> = ({ setScoreData, setScoreExists, onClose, imageResponseData }) => {
  const {
    error,
    handleCancel,
    isUploading,
    setIsUploading,
    score,
    scorerNameHidden,
    handleNameHiddenCheck,
    handleScoreInputChange,
    handleCommentChange,
    handleCommentSubmit,
    t,
  } = useInputModal({
    imageResponseData,
    setScoreExists: setScoreExists,
    setScoreData,
    onClose,
  });

  const handleChange = (e: React.FormEvent<HTMLDivElement>) => {
    e.stopPropagation();

  }

  // スコア入力値を監視 スライド変更されたタイミングで現在の平均点をリアルタイムで計算して平均点を更新
  useEffect(() => {
    if (score) {

      setScoreData(pre => ({
        ...pre,
        averageScore: Math.floor((pre.totalScore + score) / ((pre.posts + 1)))
      }));
    }
  }, [score])


  if (0 < error.length) {
    alert(error);
    setIsUploading(false);
    onClose();
  }



  return isUploading ? (
    <></>
  ) : (
    <div
      className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-900 bg-opacity-50 z-50"
      onClick={handleCancel}
    >
      <Card className="w-[350px] flex flex-col justify-between">
        <CardHeader></CardHeader>
        <CardContent className="bg-white/30 p-2 pb-5 pt-5 rounded-md mb-5">
          <div className="grid w-full items-center gap-4"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="flex space-y-1.5 p-0">
              <Input
                type="number"
                id="score"
                value={score ?? ""}
                onChange={handleScoreInputChange}
                className="bg-secondary rounded-xl text-center text-2xl text-black font-normal border-none mr-2 w-25 pt-2 pb-2"
                min={0}
                max={100}
                step={1}
              />
              <Slider
                value={[score ?? 0]}
                max={100}
                step={1}
                className={`${cn("w-[100%]")}`}
                onChange={(e: React.FormEvent<HTMLDivElement>) => e.stopPropagation()}
                onClick={(e) => e.stopPropagation()}
                onValueChange={handleCommentChange}
              />
            </div>
            <div className="text-center">
              <Checkbox
                label={t('Hide my ID')}
                checked={scorerNameHidden}
                onClickCallback={handleNameHiddenCheck}
              />
            </div>
          </div>
        </CardContent>
        <CardFooter className="flex justify-between">
          <Button size={"lg"} className="text-xl" onClick={onClose}>
            {t("Cancel")}
          </Button>
          <Button
            size={"lg"}
            className="text-black text-xl hover:opacity-80"
            variant="outline"
            onClick={handleCommentSubmit}
          >
            {t("Score")}
          </Button>
        </CardFooter>
      </Card>
    </div>
  );
};

export default ScoreInputModal;
