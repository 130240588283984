import { type ClassValue, clsx } from "clsx"
import { twMerge } from "tailwind-merge"

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

// タイムアウトを設定する関数
export function timeout(ms: number) {
  return new Promise((_, reject) => {
    setTimeout(() => {
      reject(new Error('Timeout'));
    }, ms);
  });
};

export function isIntegerString(str: string) {
  return /^\d+$/.test(str);
}

export function trimSpaces(str: string): string {
  return str.replace(/^\s+|\s+$/g, '').replace(/^[\u3000]+|[\u3000]+$/g, '');
}

export function copyToClipboard(text: string) {
  if (window === undefined) return;

  if (navigator.clipboard?.writeText) {
    navigator.clipboard.writeText(text).then(() => {
    }).catch(err => {
      console.error("クリップボードへのコピーに失敗しました: ", err);
    });
  } else {
    // execCommand のフォールバック（古いブラウザ向け）
    const textarea = document.createElement("textarea");
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
  }
}

export const isNumber = (value: any) => {
  return (typeof value === "number" && !isNaN(value))
}

/**
 * 全角文字を2カウント、半角文字を1カウントとして文字数を計算する関数
 * @param value 
 * @returns 
 */
export function countStringLength(value: string | null) {
  let count = 0;
  if (value === null) {
    return count;
  }

  for (let i = 0; i < value.length; i++) {
    const c = value.charCodeAt(i);
    // 単純な全角/半角チェック（より複雑なロジックが必要な場合は適宜調整）
    if ((c >= 0x0000 && c <= 0x00FF) || (c >= 0xFF61 && c <= 0xFF9F)) {
      count += 1; // 半角文字
    } else {
      count += 2; // 全角文字
    }
  }
  return count;
}

/**
 *  URLのバリデーション関数
 * @param url 
 * @returns 
 */
export function isValidUrl(url: string): boolean {

  const pattern = new RegExp('^(https?:\\/\\/)?' + // プロトコル
    '((([a-zA-Z\\d]([a-zA-Z\\d-]*[a-zA-Z\\d])*)\\.)+[a-zA-Z]{2,}|' + // ドメイン名
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // IP形式のアドレス
    '(\\:\\d+)?(\\/[-a-zA-Z\\d%_.~+]*)*' + // ポート番号およびパス
    '(\\?[;&a-zA-Z\\d%_.~+=-]*)?' + // クエリに含まれる可能性のある文字
    '(\\#[-a-zA-Z\\d_]*)?$', 'i'); // フラグメント識別子

  return !!pattern.test(url);
}


/**
 * 引数で指定したpx表示未満の場合は縮小して表示する
 * @param threshold 
 * @returns 
 */
export const adjustViewport = (threshold: number = 352) => {
  if (typeof window === 'undefined' || typeof document === 'undefined') {

    // サーバーサイドでは処理をスキップ
    return;
  }

  const viewport = document.querySelector('meta[name="viewport"]');

  if (!viewport) {
    console.error('Viewport meta tag is not defined in the document.');

    return;
  }

  function switchViewport() {
    const value = window.outerWidth > threshold
      ? 'width=device-width,initial-scale=1'
      : `width=${threshold}`;
    if (viewport && viewport.getAttribute('content') !== value) {
      viewport.setAttribute('content', value);
    }
  }

  window.addEventListener('resize', switchViewport, false);
  switchViewport();
}

export function mergeArraysByKey<T, K extends keyof T>(
  key: K,
  ...arrays: T[][]
): T[] {
  // マップでキーとオブジェクトを管理
  const seenKeys = new Map<T[K], T>();

  for (const array of arrays) {
    for (const item of array) {
      if (!seenKeys.has(item[key])) {
        // オブジェクトを登録
        seenKeys.set(item[key], item);
      }
    }
  }

  // Map の登録順に基づき結果を生成
  return Array.from(seenKeys.values());
}

export const formatNumber = (number: number): string => {
  return number.toLocaleString();
};


export const extractNumber = (path: string): number | null => {
  const match = path.match(/^\/group\/mypage\/([^/]+)$/);
  if (match) {
    const potentialNumber = match[1];
    const parsedNumber = parseInt(potentialNumber, 10);
    return !isNaN(parsedNumber) ? parsedNumber : null;
  }
  return null;
};

/**
 * 文字列が文字以上の場合、省略して末尾に "..." を付ける関数
 * @param input - 対象の文字列
 * @param maxLength - 最大文字数 (デフォルト: 10)
 * @returns 省略された文字列
 */
export const truncateString = ({ input, maxLength = 10 }: { input: string, maxLength?: number }): string => {

  return input.length > maxLength
    ? input.substring(0, maxLength) + "..."
    : input;
}